export const GET_SCORES = 'GET_SCORES';
export const ADD_SCORE = 'ADD_SCORE';
export const UPDATE_SCORE = 'UPDATE_SCORE';
export const DELETE_SCORE = 'DELETE_SCORE';
export const DELETE_SELECTED_SCORES = 'DELETE_SELECTED_SCORES';
export const EXPORT_SCORES = 'EXPORT_SCORES';
export const TOGGLE_SCORE = 'TOGGLE_SCORE';
export const UPLOAD_SCORE_FILE = 'UPLOAD_SCORE_FILE';

export const GET_SCORE_GENERATOR_SCORES = 'GET_SCORE_GENERATOR_SCORES';
