<script>
  export default {
    data() {
      return {
        scoreTypes: [
          { id: 0, name: 'Normal' },
          { id: 1, name: 'Warmap' },
          { id: 2, name: 'BonusGame' },
          { id: 3, name: 'GameOver' },
        ],
        handPositions: [
          { id: 1, name: "RH-CDEFG" },
          { id: 2, name: "LH-CBAGF" },
          { id: 3, name: "LH-CDEFG" },
          { id: 4, name: "RH-CDEFG LH-CBAGF" },
          { id: 5, name: "RH-CDEFG LH-CDEFG" },
          { id: 6, name: "Notes around 3C" },
          { id: 7, name: "Notes around FEGA" },
          { id: 8, name: "5 Fingers position in other key" },
          { id: 9, name: "Notes beyond ledger line" },
        ],
        intervals: [
          { id: 1, name: "2nd Step" },
          { id: 2, name: "3rd Step & Skip" },
          { id: 3, name: "Special 4th" },
          { id: 4, name: "Special 5th" },
          { id: 5, name: "Within 5th" },
          { id: 6, name: "Within 6th" },
          { id: 7, name: "Within 7th" },
          { id: 8, name: "Within 8ve" },
        ],
        rhythms: [

          /*
            Randy Aug.5.23 Update rhythm according to Lydia's feedback
            grade is removed for group rhythms
            but keep the grade to use as the location parameter to reuse existing icons
          */
          {
            id: 1,
            image: "G0-01.svg",
            grade: 1,
          },
          {
            id: 2,
            image: "G0-02.svg",
            grade: 1,
          },
          {
            id: 3,
            image: "G0-03.png",
            grade: 1,
          },
          {
            id: 4,
            image: "G2-05.svg",
            grade: 3,
          },
          {
            id: 5,
            image: "G3-03.svg",
            grade: 4,
          },
          {
            id: 6,
            image: "G3-06.svg",
            grade: 4,
          },
          {
            id: 7,
            image: "G3-05.svg",
            grade: 4,
          },
          {
            id: 8,
            image: "G0-08.png",
            grade: 1,
          },
          {
            id: 9,
            image: "G0-09.png",
            grade: 1,
          },
          {
            id: 10,
            image: "G0-10.png",
            grade: 1,
          },
          {
            id: 11,
            image: "G0-11.png",
            grade: 1,
          },
          {
            id: 12,
            image: "G0-12.png",
            grade: 1,
          },
          {
            id: 13,
            image: "G0-13.png",
            grade: 1,
          },
          {
            id: 14,
            image: "G0-14.png",
            grade: 1,
          },
        ],
        rests: [
          { id: 1, name: "With Rest" },
          { id: 2, name: "Without Rest" },
        ],
        ties: [{ id: 1, name: "None" }],
        keySignatures: {
          major: [
            { id: 1, name: "C+" },
            { id: 2, name: "G+" },
            { id: 3, name: "D+" },
            { id: 4, name: "A+" },
            { id: 5, name: "E+" },
            { id: 6, name: "B+" },
            { id: 7, name: "F+" },
            { id: 8, name: "Bb+" },
            { id: 9, name: "Eb+" },
            { id: 10, name: "Ab+" },
            { id: 11, name: "Db+" },
            { id: 12, name: "Gb+" },
            { id: 13, name: "F#+" },
            { id: 14, name: "C#+" },
          ],
          minor: [
            { id: 15, name: "C-" },
            { id: 16, name: "G-" },
            { id: 17, name: "D-" },
            { id: 18, name: "A-" },
            { id: 19, name: "E-" },
            { id: 20, name: "B-" },
            { id: 21, name: "F-" },
            { id: 22, name: "Bb-" },
            { id: 23, name: "Eb-" },
            { id: 24, name: "Ab-" },
            { id: 25, name: "Db-" },
            { id: 26, name: "Gb-" },
            { id: 27, name: "F#-" },
            { id: 28, name: "C#-" },
          ]
        },
        timeSignatures: [
          { id: 1, name: "2/4" },
          { id: 2, name: "3/4" },
          { id: 3, name: "4/4" },
          { id: 4, name: "3/8" },
          { id: 5, name: "6/8" },
          { id: 6, name: "9/8" },
          { id: 7, name: "12/8" },
          { id: 8, name: "5/4" },
          { id: 9, name: "6/4" },
          { id: 10, name: "7/4" },
          { id: 11, name: "2/2" },
          { id: 12, name: "2/8" },
          { id: 13, name: "5/8" },
          { id: 14, name: "4/8" },
          { id: 15, name: "7/8" },
          { id: 16, name: "3/2" },
          { id: 17, name: "4/2" },
        ],
        handSelections: [
          { id: 1, name: 'Right hand only' },
          { id: 2, name: 'Left hand only' },
          { id: 3, name: 'Alternate hands' },
          { id: 4, name: 'Both hands' },
        ],
        fingering: [
          { id: 1, name: '5 Fingers Position', key: 'fiveFingersPosition' },
          { id: 2, name: 'Squeezing Finger', key: 'squeezingFinger' },
          { id: 3, name: 'Crossing Finger', key: 'crossingFinger' },
          { id: 4, name: 'Spreading Finger', key: 'spreadingFinger' },
          { id: 5, name: 'Change Position', key: 'changePosition' },
          { id: 6, name: 'Crossing hand', key: 'crossingHand' },
        ],
        others: [
          { id: 1, name: 'Up Beat', key: 'upBeat' },
          { id: 2, name: 'Tie', key: 'tie' },
          { id: 3, name: 'Dynamics', key: 'dynamics' },
          { id: 4, name: 'Articulation', key: 'articulation' },
          { id: 5, name: 'Accidental', key: 'accidental' },
        ]
      }
    },
    methods: {
      getGrade(id) {
        return this.grades.find((grade) => grade.gradeId == id);
      },
      getLevel(id) {
        return this.levels.find((level) => level.levelId == id);
      },
      getStage(id) {
        return this.stages.find((stage) => stage.stageId == id);
      },
      getScoreType(id) {
        return this.scoreTypes.find((type) => type.id == id);
      },
      getHandPosition(id) {
        return this.handPositions.find((range) => range.id == id);
      },
      getInterval(id) {
        return this.intervals.find((interval) => interval.id == id);
      },
      getRhythm(id) {
        return this.rhythms.find((rhythm) => rhythm.id == id);
      },
      getRest(id) {
        return this.rests.find((rest) => rest.id == id);
      },
      getTie(id) {
        return this.ties.find((tie) => tie.id == id);
      },
      getHandSelection(id) {
        return this.handSelections.find((s) => s.id == id);
      },
      getKeySignature(id) {
        const major = this.keySignatures.major.find(x => x.id == id);
        if (major) {
          return major;
        }
        return this.keySignatures.minor.find((x) => x.id == id);
      },
      getTimeSignature(id) {
        return this.timeSignatures.find(
          (timeSignature) => timeSignature.id == id
        );
      },
      getFingeringDisplayText(fingering) {
        return fingering.map((f) => this.fingering.find((fing) => fing.id == f).name).join(', ') || '(None)';
      },
      getOthersDisplayText(others) {
        return others.map((f) => this.others.find((o) => o.id == f).name).join(', ') || '(None)';
      },
      getFingering(scores) {
        for (const score of scores) {
          let fingering = [];
          if (score.fiveFingersPosition)
            fingering.push(1);
          if (score.squeezingFinger)
            fingering.push(2);
          if (score.crossingFinger)
            fingering.push(3);
          if (score.spreadingFinger)
            fingering.push(4);
          if (score.changePosition)
            fingering.push(5);
          if (score.crossingHand)
            fingering.push(6);

          score.fingering = fingering;
        }
      },
      getOthers(scores) {
        for (const score of scores) {
          let others = [];
          if (score.upBeat)
            others.push(1);
          if (score.tie)
            others.push(2);
          if (score.dynamics)
            others.push(3);
          if (score.articulation)
            others.push(4);
          if (score.articulation)
            others.push(5);

          score.others = others;
        }
      },
    }
  }
</script>