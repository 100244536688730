import { render, staticRenderFns } from "./NavigationAnalytics.vue?vue&type=template&id=acc8959c&scoped=true"
import script from "./NavigationAnalytics.vue?vue&type=script&lang=js"
export * from "./NavigationAnalytics.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acc8959c",
  null
  
)

export default component.exports