<template>
  <div class="PreviewScore">
    <div ref="sheetMusicContainer" id="sheetMusicContainer"></div>

    <div v-if="playback" class="flex justify-center gap-4 mb-4 relative -top-8">
      <div class="text-white text-xl rounded-full w-12 h-12 flex justify-center items-center cursor-pointer sg-play-btn"
        @click="play()" style="background-color: #efb1a8;">
        <i v-if="pbEngine.state !== 'PLAYING'" class="fa fa-play"></i>
        <i v-else class="fa fa-pause"></i>
      </div>
      <div class="text-white text-xl rounded-full w-12 h-12 flex justify-center items-center cursor-pointer sg-play-btn"
        @click="stop()" style="background-color: #9ebdc6;">
        <i class="fa fa-stop"></i>
      </div>
    </div>

  </div>
</template>

<script>
  import { OpenSheetMusicDisplay } from "opensheetmusicdisplay";
  import PlaybackEngine from "@/osmd/PlaybackEngine";

  export default {
    name: "PreviewScore",
    props: {
      filename: {
        type: String,
        default: "",
      },
      drawTitle: {
        type: Boolean,
        default: true,
      },
      playback: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        myFilename: "",
        pbEngine: new PlaybackEngine(),
        osmd: null,
      };
    },
    methods: {
      loadPreview() {
        // let div = document.getElementsByClassName("PreviewScore");
        // let div = document.querySelector(".PreviewScore");
        this.$refs["sheetMusicContainer"].innerHTML = "";
        const osmd = new OpenSheetMusicDisplay(this.$refs["sheetMusicContainer"]);
        osmd.setOptions({
          drawTitle: this.drawTitle,
          followCursor: true,
        });
        osmd.load(this.myFilename).then(() => {
          osmd.render();
          this.pbEngine.loadScore(osmd);
          this.osmd = osmd;
        });
      },
      play() {
        if (this.pbEngine.state === 'PAUSED') {
          this.pbEngine.resume();
        } else if (this.pbEngine.state === 'PLAYING') {
          this.pbEngine.pause();
        } else {
          // stop other scores
          this.$EventBus.$emit('stopPlay');

          this.osmd.cursor.show();
          this.pbEngine.play();

          this.showCursor();
        }
      },
      stop() {
        this.pbEngine.stop();
        this.osmd.cursor.reset();
      },
      showCursor() {
        setTimeout(() => {
          const img = this.$refs["sheetMusicContainer"].querySelector("img");
          img.style.height = `${img.attributes.height.value}px`;
          img.style.width = `${img.attributes.width.value}px`;
        }, 100);
      },
    },
    created() { },
    mounted() {
      if (
        this.filename.split(".").pop() == "xml" ||
        this.filename.split(".").pop() == "musicxml"
      ) {
        this.myFilename = this.filename;
        this.loadPreview();
      } else {
        // add the extension to the filename
        this.myFilename = this.filename + ".musicxml";
        this.loadPreview();
      }

      this.$EventBus.$on('stopPlay', () => {
        console.log('stopPlay');
        // this.pbEngine.pause();
        this.stop();
      });
    },
    watch: {
      // filename: function () {
      //   if (
      //     this.filename.split(".").pop() == "xml" ||
      //     this.filename.split(".").pop() == "musicxml"
      //   ) {
      //     this.loadPreview();
      //   } else {
      //     // add the extension to the filename
      //     this.filename = this.filename + ".musicxml";
      //     this.loadPreview();
      //   }
      // },
      // filename with old and new value
      filename: function (oldValue, newValue) {
        if (
          this.myFilename == newValue ||
          this.myFilename == newValue + ".musicxml"
        ) {
          return;
        } else {
          if (
            this.filename.split(".").pop() == "xml" ||
            this.filename.split(".").pop() == "musicxml"
          ) {
            this.myFilename = this.filename;
            this.loadPreview();
          } else {
            // add the extension to the filename
            this.myFilename = this.filename + ".musicxml";
            this.loadPreview();
          }
        }
        // if (oldValue == "") {
        //   if (
        //     this.filename.split(".").pop() == "xml" ||
        //     this.filename.split(".").pop() == "musicxml"
        //   ) {
        //     this.loadPreview();
        //   } else {
        //     // add the extension to the filename
        //     this.filename = this.filename + ".musicxml";
        //     this.loadPreview();
        //   }
        // }
      },
      'pbEngine.currentIterationStep': {
        handler(newValue) {
          if (newValue > -1 && newValue >= this.pbEngine.iterationSteps) {
            setTimeout(() => {
              this.stop();
              this.osmd.cursor.reset();
            }, 2000)
          }
        },
        deep: true,
        immediate: false
      }
    },
  };
</script>

<style lang="scss">
  $EEskyblue: #3dd1d1;

  .PreviewScore {
    width: 100%;
    height: 100%;

    #sheetMusicContainer {
      width: 100%;
      height: 100%;

      .title {
        display: none;
      }

      img {
        filter: hue-rotate(102deg);
      }
    }

    .sg-play-btn {
      transition: all 0.1s ease-in-out;
    }

    .sg-play-btn:hover {
      scale: 1.08;
    }
  }
</style>