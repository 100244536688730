<template>
  <div class="ScoreLibraryUploadedScores" v-if="uploadedScores.length > 0">
    <ConfirmPop v-if="showConfirm" @cancel="showConfirm = false" @confirm="removeScore"
      :text="'Are you sure you want to delete the selected score?'" />
    <div v-if="!startReview && uploadedScores.length > 0">
      <div class="nav-widget">
        <div class="nav-dots">
          <b-tooltip :label="score.name" v-for="score in uploadedScores" :key="score.fileName" type="is-light">
            <div class="dot-item" :class="score.fileName == activeScore.fileName ? 'active' : ''"
              @click.prevent="changeActiveScore(score)"></div>
          </b-tooltip>
          <!-- <div class="dot-item" :class="score.fileName == activeScore.fileName ? 'active' : ''"
            v-for="score in uploadedScores" :key="score.fileName" @click.prevent="changeActiveScore(score)"></div> -->
        </div>
      </div>
      <button class="proceed-btn" @click="toggleReview()">
        Proceed to review all
        <i class="fas fa-angle-right"></i>
      </button>
      <div class="active-score">
        <form action class="placements">
          <div class="input-group">
            <!-- select for grades -->
            <select name="grade" id="grade" :class="[activeScore.gradeId == '' && showError ? 'error' : '']"
              v-model="activeScore.gradeId" @change="getLevels()">
              <option value>Select Grade</option>
              <option v-for="grade in grades" :key="grade.gradeId" :value="grade.gradeId">
                {{ grade.gradeName }}
              </option>
            </select>
            <!-- select for levels -->
            <select name="level" id="level" :class="[activeScore.levelId == '' && showError ? 'error' : '']"
              v-model="activeScore.levelId" @change="getStages()">
              <option value>Select Level</option>
              <option v-for="level in levels" :key="level.levelId" :value="level.levelId">
                {{ level.levelName }}
              </option>
            </select>
            <!-- select for stages -->
            <select name="stage" id="stage" :class="[activeScore.stageId == '' && showError ? 'error' : '']"
              v-model="activeScore.stageId">
              <option value>Select Stage</option>
              <option v-for="stage in stages" :key="stage.stageId" :value="stage.stageId">
                {{ stage.stageName }}
              </option>
            </select>

            <!-- select for score type -->
            <select v-model="activeScore.scoreType" :class="[activeScore.scoreType === '' && showError ? 'error' : '']">
              <option value="">Select Score Type</option>
              <option v-for="type in scoreTypes" :value="type.id" :key="type.id">
                {{ type.name }}
              </option>
            </select>
          </div>

          <ScoreParameters :searchQuery="activeScore" :showError="showError"></ScoreParameters>

          <div class="flex gap-4 w-1/2 mx-auto px-2">
            <input type="text" class="flex-1 px-2 upload-score-name" placeholder="Score name" v-model="activeScore.name"
              :class="[activeScore.name == '' && showError ? 'error' : '']" />
            <FileUploader class="flex-1" :file="activeScore.accompanimentPath" :filePath="'/img/library/'"
              @fileUploaded="activeScore.accompanimentPath = $event" :uploadText="'Upload Accompaniment'" />
          </div>

        </form>
        <div class="score-preview">
          <PreviewScore v-if="activeScore.file" :filename="activeScore.file"></PreviewScore>
        </div>
        <div class="score-activations">
          <!-- <div class="activation-item">
            <label for>Accidental</label>
            <b-switch
              v-model="activeScore.accidental"
              @click.prevent="activeScore.accidental = !activeScore.accidental"
            ></b-switch>
          </div>
          <div class="activation-item">
            <label for>Articulation</label>
            <b-switch
              v-model="activeScore.articulation"
              @click.prevent="
                activeScore.articulation = !activeScore.articulation
              "
            ></b-switch>
          </div>
          <div class="activation-item">
            <label for>Dynamics</label>
            <b-switch
              v-model="activeScore.dynamics"
              @click.prevent="activeScore.dynamics = !activeScore.dynamics"
            ></b-switch>
          </div> -->
          <div class="activation-item">
            <label for>Score Enabled</label>
            <b-switch v-model="activeScore.scoreEnabled" @click.prevent="
                activeScore.scoreEnabled = !activeScore.scoreEnabled
              "></b-switch>
          </div>
        </div>
        <div class="save-remove">
          <div class="save-buttons">
            <!-- <button class="save" @click="saveChanges()">Save tagging</button> -->
            <button class="remove" @click="
                scoreToDelete = activeScore;
                showConfirm = true;
              ">
              Remove Score
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="nav-widget">
        <p>Review all new scores for EE Adventure library</p>
      </div>
      <button class="back-btn" @click="startReview = false">
        <i class="fas fa-angle-left"></i>
      </button>
      <div class="active-score" v-for="(score, index) in uploadedScores" :key="index">
        <label for class="score-name">{{ score.name }}</label>
        <div class="score-preview">
          <PreviewScore :filename="score.file"></PreviewScore>
          <div class="score-place">
            <p>
              Score Placement: {{ getGrade(score.gradeId).gradeName }},
              {{ getLevel(score.levelId).levelName }},
              {{ getStage(score.stageId).stageName }},
              {{ getScoreType(score.scoreType).name }}
            </p>
          </div>
          <div class="terms">
            <!-- table of terms -->
            <table class="previewTable">
              <thead>
                <tr>
                  <th>Hand Position</th>
                  <th>Interval</th>
                  <th>rhythm</th>
                  <th>Rest</th>
                  <th>Key Signature</th>
                  <th>Time Signature</th>
                  <th>Hand Selection</th>
                  <th>Fingering</th>
                  <th>Others</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-if="score.handPosition">{{ getHandPosition(score.handPosition).name }}</td>
                  <td v-else>--</td>
                  <td v-if="score.interval">
                    {{ getInterval(score.interval).name }}
                  </td>
                  <td v-else>--</td>
                  <td v-if="score.rhythm">
                    <img :src="
                        $store.state.assets_base_url +
                        'Rhythm_Rest/' +
                        getRhythm(score.rhythm).grade +
                        '/Rhythm/' +
                        getRhythm(score.rhythm).image
                      " alt="" class="score-img" />
                  </td>
                  <td v-else>--</td>
                  <td v-if="score.rest">
                    {{ getRest(score.rest).name }}
                  </td>
                  <td v-else>--</td>

                  <td v-if="score.keySignature">
                    {{ getKeySignature(score.keySignature).name }}
                  </td>
                  <td v-else>--</td>
                  <td v-if="score.timeSignature">
                    {{ getTimeSignature(score.timeSignature).name }}
                  </td>
                  <td v-else>--</td>
                  <td v-if="score.handSelection">
                    {{ getHandSelection(score.handSelection).name }}
                  </td>
                  <td v-else>--</td>
                  <td v-if="score.fingering.length">{{ getFingeringDisplayText(score.fingering) }}</td>
                  <td v-else>--</td>
                  <td v-if="score.others.length">{{ getOthersDisplayText(score.others) }}</td>
                  <td v-else>--</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="score-activations">
          <!-- <div class="activation-item">
            <label for>Accidental</label>
            <b-switch v-model="score.accidental" @click.prevent="score.accidental = !score.accidental"></b-switch>
          </div>
          <div class="activation-item">
            <label for>Articulation</label>
            <b-switch v-model="score.articulation" @click.prevent="score.articulation = !score.articulation"></b-switch>
          </div>
          <div class="activation-item">
            <label for>Dynamics</label>
            <b-switch v-model="score.dynamics" @click.prevent="score.dynamics = !score.dynamics"></b-switch>
          </div> -->
          <div class="activation-item">
            <label for>Score Enabled</label>
            <b-switch v-model="score.scoreEnabled" @click.prevent="score.scoreEnabled = !score.scoreEnabled"></b-switch>
          </div>
        </div>
      </div>
      <div class="publish-btn">
        <button class="publish" @click="publishScores()">
          Publish to library
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import scoreLibraryMix from '@/mixin/scoreLibraryMix.vue';

  export default {
    name: "ScoreLibraryUploadedScores",
    mixins: [scoreLibraryMix],
    props: {
      uploadedScores: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        selectedScores: [],
        grades: [],
        levels: [],
        stages: [],

        activeScore: {
          gradeId: "",
          levelId: "",
          stageId: "",
          moduleId: "",
          scoreType: "",
          curriculum: "KungFuSightReading",
          handPosition: null,
          interval: null,
          rhythm: null,
          rest: null,
          keySignature: null,
          timeSignature: null,
          tie: null,
          handSelection: null,
          fingering: [],
          others: [],
        },
        scoreToDelete: null,
        showConfirm: false,
        startReview: false,
        scoresLoaded: false,
        showError: false
      };
    },
    methods: {
      getMissingTags() {
        let scoreNamesWithMissingTaggings = [];
        const tags = ['handPosition', 'interval', 'rhythm', 'rest', 'timeSignature', 'handSelection', 'keySignature'];
        for (const score of this.uploadedScores) {
          for (const key of Object.keys(score)) {
            // unselected dropdown will set value to string "null"
            if (score.hasOwnProperty(key) && score[key] === 'null') {
              score[key] = null;
            }
          }
          const tag = tags.find(x => !score[x]);
          if (tag) {
            console.log(`${score.name}, missing: ${tag}`);
            scoreNamesWithMissingTaggings.push(score.name);
          }
        }
        return scoreNamesWithMissingTaggings;
      },
      toggleReview() {
        for (const score of this.uploadedScores) {
          if (!score.name) {
            this.$toast.warning(`Please set a name for each score`, { timeout: 5000 });
            return;
          }
          if (!score.gradeId || !score.levelId || !score.stageId || score.scoreType === '') {
            this.showError = true;
            this.$toast.warning(`Please set a grade, level, stage and score type for "${score.name}"`, { timeout: 5000 });
            return;
          }
        }

        const scoreNamesWithMissingTaggings = this.getMissingTags();

        if (scoreNamesWithMissingTaggings.length) {
          this.showError = true;
          this.$buefy.dialog.confirm({
            title: 'Incomplete Tagging Detected',
            message: `Are you sure you want to proceed without completing the tagging for ${scoreNamesWithMissingTaggings.join(', ')}?`,
            cancelText: 'Cancel',
            confirmText: 'Yes, proceed',
            type: 'is-success',
            onConfirm: () => {
              this.startReview = true;
            }
          })
        } else {
          this.startReview = true;
        }


        // this.startReview = true;
        // return;
        // const keys = Object.keys(this.activeScore);
        // console.log('keys', keys);
        // if (keys.find(x => this.activeScore.hasOwnProperty(x) && !this.activeScore[x])) {
        //   this.showError = true;
        //   console.log('error!!');
        //   return;
        // }
        // if (
        //   this.activeScore.gradeId == "" ||
        //   this.activeScore.levelId == "" ||
        //   this.activeScore.stageId == "" ||
        //   this.activeScore.range == "" ||
        //   this.activeScore.interval == "" ||
        //   this.activeScore.tie == "" ||
        //   this.activeScore.keySignature == "" ||
        //   this.activeScore.timeSignature == "" ||
        //   this.activeScore.name == ""
        // ) {
        //   this.showError = true;
        //   return;
        // } else {
        //   this.startReview = true;
        //   this.showError = false;
        // }
      },
      getRhythms(grade) {
        return this.rhythms.filter((rhythm) => rhythm.grade == grade);
      },
      getRests(grade) {
        return this.rests.filter((rest) => rest.grade == grade);
      },
      getGrades() {
        this.$startLoader();
        this.$store
          .dispatch("GET_GRADES")
          .then((res) => {
            this.$stopLoader();
            this.grades = res.data.grades;
            this.getLevels();
          })
          .catch((err) => {
            this.$stopLoader();
          });
      },
      getLevels() {
        this.$startLoader();
        this.$store
          .dispatch("GET_LEVELS", this.activeScore.gradeId)
          .then((res) => {
            this.$stopLoader();
            this.levels = res.data.levels;
            this.getStages();
          })
          .catch((err) => {
            this.$stopLoader();
          });
      },
      getStages() {
        this.$startLoader();
        this.$store
          .dispatch("GET_STAGES", this.activeScore.levelId)
          .then((res) => {
            this.$stopLoader();
            this.stages = res.data.stages;
          })
          .catch((err) => {
            this.$stopLoader();
          });
      },
      changeActiveScore(score) {
        this.activeScore = score;

        // const keys = Object.keys(this.activeScore);
        // console.log('keys', keys);
        // if (keys.find(x => this.activeScore.hasOwnProperty(x) && !this.activeScore[x])) {
        //   this.showError = true;
        //   console.log('error!');
        //   return;
        // }
        // if (
        //   score.gradeId == "" ||
        //   score.levelId == "" ||
        //   score.stageId == "" ||
        //   score.range == "" ||
        //   score.interval == "" ||
        //   score.rhythm == "" ||
        //   score.rest == "" ||
        //   score.tie == "" ||
        //   score.keySignature == "" ||
        //   score.timeSignature == "" ||
        //   score.name == ""
        // ) {
        //   if (
        //     this.activeScore.gradeId == "" ||
        //     this.activeScore.levelId == "" ||
        //     this.activeScore.stageId == "" ||
        //     this.activeScore.range == "" ||
        //     this.activeScore.interval == "" ||
        //     this.activeScore.rhythm == "" ||
        //     this.activeScore.rest == "" ||
        //     this.activeScore.tie == "" ||
        //     this.activeScore.keySignature == "" ||
        //     this.activeScore.timeSignature == "" ||
        //     this.activeScore.name == ""
        //   ) {
        //     this.showError = true;
        //     return;
        //   } else {
        //     this.showError = false;
        //     this.activeScore = score;
        //   }
        // } else {
        //   this.showError = false;
        //   this.activeScore = score;
        // }
      },
      removeScore() {
        if (this.uploadedScores.length == 1) {
          this.$emit("removeUploadedScore", this.scoreToDelete);
        } else {
          this.$emit("removeUploadedScore", this.scoreToDelete);
          let remainingScores = this.uploadedScores.filter(
            (score) => score.fileName != this.scoreToDelete.fileName
          );
          this.activeScore = remainingScores[0];
        }
        this.showConfirm = false;
      },

      changeSelectedrhythm(rhythm) {
        this.activeScore.rhythm = rhythm;
      },
      publishScores() {
        // loop through scores and check if it has accompanimentPath and set hasAccompaniment to true else false
        this.uploadedScores.forEach((score) => {
          if (
            score.accompanimentPath &&
            score.accompanimentPath != "" &&
            score.accompanimentPath != null &&
            score.accompanimentPath != undefined
          ) {
            score.hasAccompaniment = true;
          } else {
            score.hasAccompaniment = false;
          }
          // if (score.rest == "" || score.rest == null) {
          //   score.rest = 0
          // }
          // if (score.rhythm == "" || score.rhythm == null) {
          //   score.rhythm = 0
          // }
        });
        this.$emit("publishScores", this.uploadedScores);
      },
    },
    mounted() {
      this.activeScore = this.uploadedScores[0];
      console.log('this.activeScore', this.activeScore);
      // if (!this.activeScore) {

      // }
      this.getGrades();
    },
    watch: {
      uploadedScores: function () {
        if (!this.scoresLoaded) {
          this.activeScore = this.uploadedScores[0];
          this.scoresLoaded = true;
          console.log('this.activeScore watch', this.activeScore);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  $EEskyblue: #3dd1d1;

  .ScoreLibraryUploadedScores {
    background: white;
    margin: 2rem 0;
    width: 100%;
    // padding: 2rem;
    border-radius: 20px;
    box-shadow: 2px 5px 10px 1px #b6b6b620;
    position: relative;
    padding-bottom: 4rem;

    .upload-score-name {
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .nav-widget {
      padding: 2rem;

      .nav-dots {
        display: flex;
        justify-content: center;
        column-gap: 20px;
        width: 100%;

        .dot-item {
          border: 1px #d9d9d9 solid;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #fff;
          cursor: pointer;

          &.active {
            background: $EEskyblue;
          }
        }
      }

      p {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    .proceed-btn {
      position: absolute;
      color: #555;
      background: $EEskyblue;
      font-size: 1rem;
      border-radius: 50px 0px 0px 50px;
      padding: 12px 25px;
      right: 0;
      top: 20px;
      border: none;
      font-weight: 600;
      cursor: pointer;

      i {
        margin-left: 10px;
      }
    }

    .back-btn {
      position: absolute;
      color: #fff;
      background: $EEskyblue;
      left: 10px;
      top: 20px;
      width: 40px;
      height: 40px;
      border: none;
      font-size: 1.5rem;
      border-radius: 50%;
      box-shadow: 2px 2px 20px 1px #dedede;
      cursor: pointer;
    }

    .active-score {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .placements {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        width: 100%;
        padding: 0 24px;

        .input-group {
          display: flex;
          justify-content: center;
          column-gap: .5rem;

          select,
          .dropd {
            width: 170px;
            height: 40px;
            border: 1px #d9d9d9 solid;
            border-radius: 5px;
            padding: 0 10px;
            font-size: 1rem;
            color: #555;
            background: #fff;
            cursor: pointer;

            &.error {
              border: 1px #ff00009b solid;
            }
          }

          input[type="text"] {
            width: 350px;
            border: none;
            border-radius: 5px;
            padding: 10px;
            font-size: 1.1rem;
            color: #555;
            background: #fff;
            cursor: pointer;
            font-weight: 500;
            margin-top: 2rem;
            box-shadow: 2px 2px 10px 3px #e8e8e8;

            &.error {
              border: 1px #ff00009b solid;
            }
          }
        }
      }

      .score-preview {
        margin-top: 2rem;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          width: 100%;

          &.mrgt {
            margin-top: 3rem;
          }
        }

        .score-place {
          p {
            font-size: 1.1rem;
            margin-top: 2rem;
          }
        }
      }

      .score-activations {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        column-gap: 2rem;

        .activation-item {
          display: flex;
          column-gap: 1rem;

          label {
            font-size: 1.1rem;
            font-weight: 500;
          }
        }
      }

      .save-remove {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;

        .save-buttons {
          display: flex;
          column-gap: 2rem;

          button {
            width: 150px;
            padding: 10px;
            font-size: 18px;
            font-weight: 700;
            color: #555;
            border: none;
            border-radius: 10px;
            display: inline-block;
            cursor: pointer;

            &.save {
              background-color: $EEskyblue;
            }

            &.remove {
              background-color: transparent;
              border-bottom: 1px #333 solid;
              border-radius: 0;
              padding-bottom: 5px;
            }
          }
        }
      }

      .score-name {
        position: absolute;
        color: #555;
        background: $EEskyblue;
        font-size: 1rem;
        border-radius: 0px 50px 50px 0px;
        padding: 12px 25px;
        left: 0;
        top: 20px;
        border: none;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .terms {
      width: 90%;
      border: 1px #888 solid;
      border-radius: 10px;
      margin-top: 1rem;

      .previewTable {
        border-radius: 10px;
        overflow: hidden;
        width: 100%;

        thead {
          background: $EEskyblue;
          border: 1px #888 solid;
          font-size: 0.9rem;
          border-radius: 10px;
        }

        td,
        th {
          padding: 5px 1px;
          text-align: center;
        }

        th {
          border-right: 1px #fff solid;

          &:nth-last-child(1) {
            border-right: none;
          }
        }

        tbody {
          td {
            padding: 5px 1px;
            border: 1px #888 solid;

            span {
              font-size: 1.25rem;
            }
          }
        }
      }
    }

    .publish-btn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 3.5rem;

      button {
        width: 200px;
        padding: 10px;
        font-size: 19px;
        font-weight: 700;
        color: #555;
        border: none;
        border-radius: 10px;
        display: inline-block;
        cursor: pointer;
        background-color: $EEskyblue;
        box-shadow: 3px 2px 10px 1px #cecece;
      }
    }
  }

  .score-img {
    height: 20px;
  }
</style>