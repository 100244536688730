<template>
  <div class="EEDropdown">
    <div @click="show = !show" :class="long ? 'long' : ''">
      <span v-if="!startSelect" class="listName">{{ listName }}</span>
      <!-- <span
        v-if="selected && startSelect"
        v-html="selected.code"
        class="with-symbols"
      ></span> -->
      <img v-if="selected && startSelect && selected.image" :src="
          $store.state.assets_base_url +
          'Rhythm_Rest/' +
          selected.grade +
          '/' +
          listName +
          '/' +
          selected.image
        " alt="" />
      <!-- <img
        v-if="value != '' && value != null"
        :src="
          $store.state.assets_base_url +
          'Rhythm_Rest/' +
          selected.grade +
          '/' +
          listName +
          '/' +
          getImage(value)
        "
        alt=""
      /> -->
      <!-- <span
        v-if="value != '' && value != null"
        class="with-symbols"
        v-html="getCode(value)"
      ></span> -->
      <i class="fa-solid fa-angle-down" v-if="!show"></i>
      <i class="fa-solid fa-angle-up" v-if="show"></i>
    </div>
    <ul v-show="show" class="with-symbols" ref="dropList">
      <li class="name" @click="select(null)">{{ listName }}</li>
      <!-- <li
        v-for="item in items"
        @click="select(item)"
        :key="item.id"
        v-html="item.code"
      ></li> -->
      <li v-for="item in items" @click="select(item)" :key="item.id">
        <img :src="
            $store.state.assets_base_url +
            'Rhythm_Rest/' +
            item.grade +
            '/' +
            listName +
            '/' +
            item.image
          " alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
      listName: {
        type: String,
        default: "select",
      },
      value: {
        type: Number | String | null,
      },
      long: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: false,
        selected: "",
        startSelect: false,
      };
    },
    methods: {
      select(item) {
        this.selected = item;
        this.show = false;
        this.startSelect = !!item;
        this.$emit("selected", item?.id);

      },
      getCode() {
        if (this.value != "" && this.value != null) {
          this.items.forEach((element) => {
            if (element.id == this.value) {
              return element.code;
            }
          });
        }
      },
      getImage() {
        if (this.value != "" && this.value != null) {
          this.items.forEach((element) => {
            if (element.id == this.value) {
              return element.image;
            }
          });
        }
      },
    },
    mounted() {
      document.addEventListener("click", (e) => {
        if (!this.$el.contains(e.target)) {
          this.show = false;
        }
      });
      if (this.value == "" || this.value == null) {
        this.startSelect = false;
        this.selected = "";
      } else {
        this.startSelect = true;
        this.selected = this.items.find((item) => item.id == this.value);
      }
    },
    watch: {
      items(newVal, oldVal) {
        if (newVal != oldVal) {
          // this.startSelect = false;
        }
      },

      value: function () {
        if (this.value == "" || this.value == null) {
          this.startSelect = false;
          this.selected = "";
        } else {
          this.startSelect = true;
          this.selected = this.items.find((item) => item.id == this.value);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .EEDropdown {
    position: relative;
    display: inline-block;
    min-width: 100px;
    width: 100%;
    border: 1px solid #ccc;
    background-color: white;

    .with-symbols {
      max-height: 300px;
      overflow: auto;
    }

    .button.is-primary:focus,
    .button.is-primary.is-focused {
      color: #777;
    }
  }

  div {
    cursor: pointer;
    //   padding: 8px;
    border: none;
    border-radius: 4px;
    position: relative;

    i {
      position: absolute;
      right: 4px;
      top: 5px;
      opacity: 1;
      font-size: 0.75rem;
      font-weight: bolder;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 8px;
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    border: none;
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0px 3px 30px 5px #0000002e;
    z-index: 1;
  }

  li {
    padding: 8px;
    font-size: 1.25rem;
    letter-spacing: 2px;

    &.name {
      font-size: 1rem;
      font-weight: bold;
      padding: 5px 2px;
      padding-top: 15px;
      letter-spacing: 0;
    }
  }

  li:hover {
    background-color: #f2f2f2;
  }

  .listName {
    font-size: 0.9rem;
  }

  .long {
    margin-top: 8px;
    text-align: left;
    padding-left: 4px;
  }

  img {
    height: 20px;
    margin: auto;
  }
</style>