<template>
  <div class="FileUploader">
    <audio controls v-if="newFile && newFile != ''">
      <source :src="$store.state.scores_base_url + newFile" type="audio/mp3" v-if="newFile" />
      Your browser does not support the audio element.
    </audio>
    <div class="drop-zone">
      <div class="drop-zone__input">
        <input ref="input" type="file" name="file" id="file" class="drop-zone__input"
          @change="fileFile = $event.target.files[0]" />
        <label for="file" class="drop-zone__label">
          <span v-if="newFile">Change File</span>
          <span v-else>{{ uploadText }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FileUploader",
    props: {
      file: {
        type: String,
        default: "",
      },
      filePath: {
        type: String,
        default: "",
      },
      uploadText: {
        type: String,
        default: "Upload File",
      },
    },
    data() {
      return {
        fileFile: null,
        newFile: "",
      };
    },
    methods: {
      showUploader() {
        this.$refs.input.click();
      },
      uploadFile() {
        this.$startLoader();
        // check if the file is an audio file
        if (this.fileFile.type !== "audio/mpeg") {
          this.$stopLoader();
          this.$showNotification("Please upload an audio file");
          return;
        }
        const formData = new FormData();
        formData.append("file", this.fileFile);
        // dispatch("uploadfile", formData);
        if (this.fileFile) {
          this.newFile = "";
          this.$store.dispatch("UPLOAD_SCORE_FILE", formData).then((res) => {
            if (res.status === 200) {
              this.newFile = res.data.filePath + res.data.fileExt;
              this.$emit("fileUploaded", this.newFile);
              console.log(res.data);
              this.$showNotification("File uploaded successfully");
            } else {
              this.$showNotification("File upload failed");
            }
            this.$stopLoader();
          });
        } else {
          this.$emit("fileUploaded", "");
          this.$stopLoader();
        }
      },
    },
    watch: {
      fileFile() {
        this.uploadFile();
      },
    },
  };
</script>

<style lang="scss" scoped>
  $EEskyblue: #3dd1d1;

  .FileUploader {
    position: relative;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    .drop-zone {
      width: 200px;
      height: 45px;
      // border: 3px dashed #ddd;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        border-color: $EEskyblue;
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 2;
        border: none;
        cursor: pointer;
      }

      label {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        color: $EEskyblue;
        z-index: 1;
        border-bottom: 1px solid $EEskyblue;
        // text shadow
        text-shadow: 1px 1px 1px rgba(104, 104, 104, 0.884);
        cursor: pointer;
      }

      &__thumb {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
</style>