<template>
  <div class="w-full h-full relative overflow-hidden">
    <div class="absolute right-10 top-3 flex gap-8" style="right: 10%;">
      <p class="hover:underline cursor-pointer" @click="changeLanguage('cn')">中</p>
      <p class="hover:underline cursor-pointer" @click="changeLanguage('en')">EN</p>
    </div>
    <div class="w-full h-28">
      <a href="http://www.eemusic.com.sg/" target="_blank">
        <img src="https://scores.s3.cn-north-1.amazonaws.com.cn/items/logo.png" class="object-contain relative"
          style="width:12%;min-width:200px;left:10%;top:50px" alt="">
      </a>
    </div>
    <div class="w-full">
      <img src="https://scores.s3.cn-north-1.amazonaws.com.cn/items/banner-s.png" class="w-full" alt="">
    </div>
    <div class="sg-taggings-area w-4/5 mx-auto relative" :class="{'overflow-hidden':showPDF}"
      style="min-width: 1000px;">
      <div class="mt-6 relative">
        <div class="flex gap-2">
          <div class="flex-1 sg-block" :class="{ 'sg-block-on': course === 'ABRSM' }" @click="setCource('ABRSM')">ABRSM
            Grading
          </div>
          <div class="flex-1 sg-block" :class="{ 'sg-block-on': course === 'freestyle' }"
            @click="setCource('freestyle')">
            Freestyle
          </div>
        </div>

        <div class="flex gap-2 mt-2" :class="{'sg-disabled': course==='freestyle'}">
          <div class="flex-1 sg-block relative" v-for="(item,index) in grade" :key="item.name" :value="item.name"
            :class="{ 'sg-block-on': item.id===selectedGrade, 'cursor-default': item.locked }"
            @click="setGrade(item,index)">{{ item.name }}
            <i class="fa fa-lock" v-if="item.locked"></i>
          </div>
        </div>

        <!-- 暂不启用fingering，原本的设计是grade 3时用fingering代替hand position。若要启用，请将下面这行添加到<div v-for="(tagging,key) in taggings" class="container-box"下 -->
        <!-- :class="{'hidden':(key==='fingering'&&selectedGrade!=='g3')||(key==='handPosition'&&selectedGrade==='g3')}" -->
        <div class="flex gap-2 mt-2">
          <div v-for="(tagging,key) in taggings" class="container-box" :style="{'width':rendering[key].width}">
            <div class="box-header">{{rendering[key][language] || rendering[key].name}}</div>

            <!-- keySignature(调号)分为major和minor，在下面单独处理 -->
            <div v-if="key!=='keySignature'" class="box-content h-72 gap-2 justify-center overflow-auto"
              :style="{'overflow-y':key==='rhythm'?'hidden':'auto'}"
              :class="{'block':key==='timeSignature'&&course==='freestyle','flex':key!=='timeSignature'||selectedGrade}">
              <!-- 每个选项 -->
              <div v-for="(item,index) in taggings[key]" :key="item.name" :value="item.name"
                @click="select(item, taggings[key])" class="cursor-pointer font-semibold text-gray-500"
                :class="{ 'text-red-400': item.checked, 'hidden': selectedGrade&&!item.grade.includes(selectedGrade) }">

                <div v-if="!item.tipImage">
                  <div v-if="item.name">{{ item[language] || item.name }}</div>
                </div>
                <b-tooltip ref="tooltip" v-if="item.tipImage" position="is-right" multilined :triggers="['click']"
                  :auto-close="['outside', 'escape']" @open="tooltipOpen(index, key)">
                  <div v-if="item.name">{{ item[language] || item.name }}</div>
                  <template v-slot:content>
                    <img :src="scores_base_url + item.tipImage" alt="" class="h-full w-full">
                  </template>
                </b-tooltip>

                <div v-if="item.image" class="flex justify-center mb-2 sg-rhythm">
                  <img v-if="!item.checked" :src="assets_base_url + item.image" alt="" class="h-7">
                  <img v-else :src="assets_base_url + item.selected" alt="" class="h-7">
                </div>
              </div>
            </div>

            <div v-if="key==='keySignature'" class="box-content h-72 flex flex-row gap-12 justify-center"
              style="flex-direction: row;" :style="{'font-size':!selectedGrade?'14px':'16px'}">
              <div class="flex flex-col justify-center">
                <div v-for="(item,index) in taggings.keySignature.major" :key="item.name" :value="item.name"
                  @click="select(item, taggings.keySignature)" class="cursor-pointer font-semibold text-gray-500"
                  :class="{ 'text-red-400': item.checked, 'hidden': selectedGrade&&!item.grade.includes(selectedGrade)}">
                  {{
                  item.name }}</div>
              </div>
              <div v-if="selectedGrade!=='pg'" class="flex flex-col justify-center">
                <div v-for="(item,index) in taggings.keySignature.minor" :key="item.name" :value="item.name"
                  @click="select(item, taggings.keySignature)" class="cursor-pointer font-semibold text-gray-500"
                  :class="{ 'text-red-400': item.checked, 'hidden': selectedGrade&&!item.grade.includes(selectedGrade)}">
                  {{
                  item.name }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="mt-2 border border-gray-300 rounded-md p-3">
          <p class="text-left font-semibold text-gray-500 mb-4">{{language==='cn'?'其他参数':'Other Parameters'}}</p>
          <div class="flex gap-10 justify-start max-w-5xl mb-3">
            <div v-for="(item,index) in other" :key="index">
              <b-checkbox v-model="item.checked" type="is-info" class="mt-5">
                {{item[language] || item.name}}
              </b-checkbox>
            </div>
          </div>
        </div> -->

        <div class="flex gap-2 mt-2">
          <div class="sg-selected-taggings h-36 border border-gray-300 rounded-md p-3 flex-1">
            <p class="text-left font-semibold text-gray-500 mb-4">{{language==='cn'?'已选的参数':'Selected Parameters'}}</p>
            <div class="flex gap-4">
              <div v-for="(tagging,index) in taggings" :key="index"
                class="px-4 py-2 bg-gray-200 rounded-sm font-semibold text-gray-500 relative"
                :class="{'hidden': !getSelected(tagging)}">
                <div v-if="getSelected(tagging) && getSelected(tagging).image">
                  <img :src="assets_base_url + getSelected(tagging).image" alt="" class="h-6">
                </div>
                <div v-else>{{getSelected(tagging) && (getSelected(tagging)[language] || getSelected(tagging).name)}}
                </div>
                <div @click="removeSelection(tagging)"
                  class="absolute w-5 h-5 rounded-full bg-red-200 flex justify-center items-center -right-2 -top-2 cursor-pointer">
                  <i class="fa fa-close text-white"></i>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="h-36 border border-gray-300 rounded-md w-36" style="flex: 0 0 144px;">
            <div v-for="(tagging,index) in taggings" :key="index"
              class="px-4 py-2 bg-gray-200 rounded-sm font-semibold relative"
              :class="{'hidden': !getSelected(tagging) || !getSelected(tagging).tipImage}">
              <div v-if="getSelected(tagging) && getSelected(tagging).tipImage">
                <img :src="scores_base_url + getSelected(tagging).tipImage" alt="" class="h-full w-full">
              </div>
            </div>
          </div> -->

        </div>


        <div class="mt-2 mb-6 border border-gray-300 rounded-md p-10 relative">
          <div class="flex gap-6 items-center w-4/5 mx-auto">
            <p class="whitespace-nowrap">{{language==='cn'?'选择生成乐谱的数量':'Select number of exercises'}}</p>
            <b-field class="w-full">
              <b-slider type="is-info" size="is-small" v-model="scoreCount" :min="1" :max="10" indicator
                :tooltip="false"></b-slider>
            </b-field>
          </div>

          <div class="rounded-2xl text-white font-semibold flex w-4/5 mx-auto h-24 text-2xl items-center mt-5"
            style="background-color: #9ebdc6;">
            <div class="w-1/2 h-2/3 flex items-center justify-center cursor-pointer" @click="generateScores()"
              style="background-color: #9ebdc6;">
              <div v-if="!loading"><i class="fa fa-music mr-5"></i>{{language==='cn'?'生成乐谱':'Generate Scores'}}</div>
              <div v-if="loading" class="flex gap-4 items-center"><span class="sg-loader"></span>Loading...</div>
            </div>
            <div class="h-2/3 cursor-pointer flex items-center justify-center"
              style="width:48%;background-color: #efb1a8;" @click="saveAsPDF()">
              <i class="fa fa-file-pdf mr-5"></i>
              {{language==='cn'?'保存PDF':'Save as PDF'}}
            </div>
          </div>

          <div v-if="!loading&&scores&&scores.length" class="border border-gray-300 rounded-md mt-6 p-12">
            <div class="flex justify-between mb-24">
              <p>{{language==='cn'?'匹配的曲谱总数':'Matched scores'}}: {{matchedScoreCount}}</p>
              <p class="cursor-pointer" @click="generateScoresForSelected()"><i class="fa fa-refresh mr-3"></i><span
                  class="font-semibold text-gray-500">{{language==='cn'?'更新选中的曲谱':'Refresh selected scores'}}</span></p>
            </div>

            <div v-for="(score, index) in scores" :key="index" class="border-b-2 border-blue-100 pb-6 sg-single-score">
              <div v-if="!score.loading" class="flex justify-between mt-10 -mb-1 relative z-10">
                <p class="cursor-pointer text-left ml-28" @click="generateScoreAt(index)">
                  <i class="fa fa-refresh mr-3"></i><span
                    class="font-semibold text-gray-500">{{language==='cn'?'更新此曲谱':'Refresh this score'}}</span>
                </p>
                <p class="mr-10">Id: {{score.id}}</p>
              </div>

              <div v-if="!score.loading">
                <div class="flex gap-2">
                  <b-checkbox v-model="score.checked" type="is-info" class="mt-5">
                  </b-checkbox>
                  <!-- <PreviewScore :filename="scores_base_url + score.score" :drawTitle="true" :playback="true">
                  </PreviewScore> -->

                  <!-- opcity will be set to 1 after all need to hide elements are hidden -->
                  <div v-if="score.scoreSvg" class="sg-score-svg w-full h-full relative opacity-0"
                    v-html="score.scoreSvg" :style="{'top':settings.isShowTitle?'0':'-100px'}">
                  </div>

                </div>

                <div class="flex gap-3 pl-24 pr-12">
                  <div v-for="(tagging,key) in score.taggings" :key="key" class="container-box"
                    :style="{'width':rendering[key].width}">
                    <div class="box-header">{{rendering[key][language] || rendering[key].name}}</div>
                    <div class="box-content h-12 flex items-center justify-center sg-rhythm overflow-hidden">
                      <p v-if="key!=='rhythm'">{{tagging}}</p>
                      <div v-else>
                        <img v-if="tagging" :src="assets_base_url + getRhythmById(tagging).image" alt="" class="h-8">
                        <p v-else>--</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="score.loading" class="flex gap-4 items-center justify-center h-60"><span
                  class="sg-loader"></span>Loading...</div>
            </div>

            <div class="fixed bottom-10 text-gray-400 cursor-pointer" style="right:3%" @click="scrollToTop()">
              <i class="fa fa-arrow-up-from-bracket text-2xl"></i>
              <p>Scroll to top</p>
            </div>
          </div>

          <div v-if="!loading&&scores&&!scores.length" class="mt-16 w-full flex flex-col items-center">
            <img src="https://scores.s3.cn-north-1.amazonaws.com.cn/items/no-music-500.png" alt="" class="opacity-30">
            <p class="text-gray-400 text-3xl">No music found...</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showPDF" @click="showPDF=false"
      class="absolute left-0 top-0 h-full w-full flex justify-center generate-pdf" style="background-color: #d3d3d378;">
      <div class="bg-white fixed" style="width: 800px;height:80vh;top:7%;">
        <vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="true" :preview-modal="true"
          :paginate-elements-by-height="1100" filename="EEMusic_Scores" :pdf-quality="2" :manual-pagination="false"
          pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" @progress="progress"
          @hasStartedGeneration="progress" @hasGenerated="loadingPDF=false" ref="html2Pdf">
          <section slot="pdf-content">
            <!-- <PreviewScore :filename="currentPDF" :drawTitle="false"></PreviewScore> -->

            <!-- opcity will be set to 1 after all need to hide elements are hidden -->
            <div v-if="currentPDF" class="sg-score-svg w-full h-full relative opacity-0" v-html="currentPDF">
            </div>
          </section>
        </vue-html2pdf>

        <div v-if="getCheckedScores().length>1" class="flex relative" style="top:-52%;">
          <div @click.stop="prevPDF()"
            class="w-14 h-14 bg-[#0000009c] text-white text-base relative -left-32 rounded-full flex justify-center items-center transition-all hover:scale-[1.08]"
            :style="{'background-color':hasPrev ? '#0000009c' : '#d3d3d3', 'cursor': hasPrev ? 'pointer' : 'not-allowed'}">
            <i class="fa-solid fa-chevron-left text-2xl"></i>
          </div>
          <div @click.stop="nextPDF()"
            class="w-14 h-14 bg-[#0000009c] text-white relative rounded-full flex justify-center items-center transition-all hover:scale-[1.08]"
            style="left: 812px"
            :style="{'background-color':hasNext ? '#0000009c' : '#d3d3d3', 'cursor': hasNext ? 'pointer' : 'not-allowed'}">
            <i class="fa-solid fa-chevron-right text-2xl"></i>
          </div>
        </div>

        <div class="flex gap-4 justify-center relative -top-4">
          <button v-if="!loadingPDF" @click.stop="generatePDF()"
            class="bg-yellow-400 w-44 py-3 rounded shadow-md text-lg">
            <i class="fa fa-print mr-2"></i>{{language==='cn'?'下载此PDF':'Export PDF'}}
          </button>
          <div v-else class="flex gap-4 items-center px-6 py-2 bg-white rounded-md"><span
              class="sg-loader"></span>Loading...</div>
          <button v-if="!loadingPDF" class="bg-gray-400 w-44 py-3 rounded shadow-md text-lg"
            @click.stop="loadingPDF=false;showPDF=false;">{{language==='cn'?'取消':'Cancel'}}</button>
        </div>
      </div>


    </div>
  </div>

</template>

<script>
  import axios from 'axios';

  export default {
    name: "ScoreGenerator",
    data() {
      return {
        language: 'cn',
        assets_base_url: "https://eemusic.s3.ap-southeast-1.amazonaws.com/",
        scores_base_url: "https://scores.s3.cn-north-1.amazonaws.com.cn/",
        course: 'freestyle',
        scoreCount: 5,
        scores: null,
        originalScores: null,
        matchedScoreCount: 0,
        idNotIn: [],
        loading: false,
        loadingPDF: false,
        showPDF: false,
        currentPDFIndex: 0,
        currentPDF: null,
        hasNext: false,
        hasPrev: false,
        settings: {
          isShowTitle: false,
          isShowLyrics: false,
          isShowStaffText: false,
          isShowText: false,
        },
        grade: [
          {
            id: 'pg',
            name: 'Pre-grade',
            selected: true,
            locked: false,
          }
        ],
        selectedGrade: null,
        taggings: {
          handPosition: [
            {
              id: 1,
              name: 'RH-CDEFG',
              cn: '右手-CDEFG',
              grade: ['pg', 'g1'],
              checked: false,
              tipImage: 'items/Asset+14%404x.png'
            },
            {
              id: 2,
              name: 'LH-CBAGF',
              cn: '左手-CBAGF',
              grade: ['pg', 'g1'],
              checked: false,
              tipImage: 'items/Asset+13%404x.png'
            },
            {
              id: 3,
              name: 'LH-CDEFG',
              cn: '左手-CDEFG',
              grade: ['g1'],
              checked: false,
              tipImage: 'items/Asset+12%404x.png'
            },
            {
              id: 4,
              name: 'RH-CDEFG LH-CBAGF',
              cn: 'C反向手位',
              grade: ['pg', 'g1', 'g2', 'g3'],
              checked: false,
              tipImage: 'items/Asset+11%404x.png'
            },
            {
              id: 5,
              name: 'RH-CDEFG LH-CDEFG',
              cn: 'C同向手位',
              grade: ['g1', 'g2', 'g3'],
              checked: false,
              tipImage: 'items/Asset+10%404x.png'
            },
            {
              id: 6,
              name: 'Notes around 3C',
              cn: '3C周围的音（3C定位）',
              grade: ['g1', 'g2', 'g3'],
              checked: false,
              tipImage: 'items/Asset+9%404x.png'
            },
            {
              id: 7,
              name: 'Notes around FEGA',
              cn: 'FEGA周围的音',
              grade: ['g2', 'g3'],
              checked: false,
              tipImage: 'items/Asset+8%404x.png'
            },
            {
              id: 8,
              name: '5 Fingers position in other key',
              cn: '其他调性5指手法',
              grade: ['g1', 'g2', 'g3'],
              checked: false,
              tipImage: 'items/other-keys.png'
            },
            {
              id: 9,
              name: 'Notes beyond ledger line',
              cn: '加线上的音',
              grade: ['g2', 'g3'],
              checked: false,
              tipImage: 'items/Asset+7%404x.png'
            },
          ],
          // 暂不启用fingering
          // fingering: [
          //   { id: 1, name: '5 Fingers Position', grade: ['g3'], checked: false, key: 'fiveFingersPosition' },
          //   { id: 2, name: 'Squeezing Finger', grade: ['g3'], checked: false, key: 'squeezingFinger' },
          //   { id: 3, name: 'Crossing Finger', grade: ['g3'], checked: false, key: 'crossingFinger' },
          //   { id: 4, name: 'Spreading Finger', grade: ['g3'], checked: false, key: 'spreadingFinger' },
          //   { id: 5, name: 'Change Position', grade: ['g3'], checked: false, key: 'changePosition' },
          //   { id: 6, name: 'Crossing hand', grade: ['g3'], checked: false, key: 'crossingHand' },
          // ],
          interval: [
            {
              id: 1,
              name: '2nd Step',
              cn: '2度音程',
              grade: ['pg', 'g1'],
              checked: false,
              tipImage: 'items/Asset+21%404x.png'
            },
            {
              id: 2,
              name: '3rd Step & Skip',
              cn: '3度音程',
              grade: ['pg', 'g1'],
              checked: false,
              tipImage: 'items/Asset+20%404x.png'
            },
            {
              id: 3,
              name: 'Special 4th',
              cn: '4度音程',
              grade: ['pg', 'g1', 'g2', 'g3'],
              checked: false,
              tipImage: 'items/Asset+19%404x.png'
            },
            {
              id: 4,
              name: 'Special 5th',
              cn: '5度音程（不含4度）',
              grade: ['pg', 'g1', 'g2', 'g3'],
              checked: false,
              tipImage: 'items/Asset+18%404x.png'
            },
            {
              id: 5,
              name: 'Within 5th',
              cn: '5度内音程',
              grade: ['g1', 'g2', 'g3'],
              checked: false,
              tipImage: 'items/Asset+18%404x.png'
            },
            {
              id: 6,
              name: 'Within 6th',
              cn: '6度内音程',
              grade: ['g3'],
              checked: false,
              tipImage: 'items/Asset+17%404x.png'
            },
            {
              id: 7,
              name: 'Within 7th',
              cn: '7度内音程',
              grade: ['g3'],
              checked: false,
              tipImage: 'items/Asset+16%404x.png'
            },
            {
              id: 8,
              name: 'Within 8ve',
              cn: '8度内音程',
              grade: ['g3'],
              checked: false,
              tipImage: 'items/Asset+15%404x.png'
            },
          ],
          timeSignature: [
            { id: 1, name: "2/4", grade: ['pg', 'g1', 'g2', 'g3'], checked: false },
            { id: 2, name: "3/4", grade: ['pg', 'g1', 'g2', 'g3'], checked: false },
            { id: 3, name: "4/4", grade: ['pg', 'g1', 'g2', 'g3'], checked: false },
            { id: 4, name: "3/8", grade: ['g1', 'g2', 'g3'], checked: false },
            { id: 5, name: "6/8", grade: ['g3'], checked: false },
            { id: 6, name: "9/8", grade: [], checked: false },
            { id: 7, name: "12/8", grade: [], checked: false },
            { id: 8, name: "5/4", grade: [], checked: false },
            { id: 9, name: "6/4", grade: [], checked: false },
            { id: 10, name: "7/4", grade: [], checked: false },
            { id: 11, name: "2/2", grade: [], checked: false },
            { id: 12, name: "2/8", grade: [], checked: false },
            { id: 13, name: "5/8", grade: [], checked: false },
            { id: 14, name: "4/8", grade: [], checked: false },
            { id: 15, name: "7/8", grade: [], checked: false },
            { id: 16, name: "3/2", grade: [], checked: false },
            { id: 17, name: "4/2", grade: [], checked: false },
          ],
          rhythm: [
            {
              id: 1,
              image: "Rhythm_Rest/1/Rhythm/G0-01.svg",
              selected: 'Rhythm_Rest/selected/G0-01_s.png',
              grade: ['pg', 'g1'],
              checked: false
            },
            {
              id: 2,
              image: "Rhythm_Rest/1/Rhythm/G0-02.svg",
              selected: 'Rhythm_Rest/selected/G0-02_s.png',
              grade: ['pg', 'g1', 'g2'],
              checked: false
            },
            {
              id: 3,
              image: "Rhythm_Rest/1/Rhythm/G0-03.png",
              selected: 'Rhythm_Rest/selected/G0-03_s.png',
              grade: ['g1', 'g2', 'g3'],
              checked: false
            },
            {
              id: 4,
              image: "Rhythm_Rest/3/Rhythm/G2-05.svg",
              selected: 'Rhythm_Rest/selected/G2-05_s.png',
              grade: ['g2', 'g3'],
              checked: false
            },
            {
              id: 5,
              image: "Rhythm_Rest/4/Rhythm/G3-03.svg",
              selected: 'Rhythm_Rest/selected/G3-03_s.png',
              grade: ['g2', 'g3'],
              checked: false
            },
            {
              id: 6,
              image: "Rhythm_Rest/4/Rhythm/G3-06.svg",
              selected: 'Rhythm_Rest/selected/G3-06_s.png',
              grade: ['g3'],
              checked: false
            },
            {
              id: 7,
              image: "Rhythm_Rest/4/Rhythm/G3-05.svg",
              selected: 'Rhythm_Rest/selected/G3-05_s_fixed.png',
              grade: ['g3'],
              checked: false
            },
            // {
            //   id: 8,
            //   image: "G0-08.png",
            //   grade: 1,
            // },
            // {
            //   id: 9,
            //   image: "G0-09.png",
            //   grade: 1,
            // },
            // {
            //   id: 10,
            //   image: "G0-10.png",
            //   grade: 1,
            // },
            // {
            //   id: 11,
            //   image: "G0-11.png",
            //   grade: 1,
            // },
            // {
            //   id: 12,
            //   image: "G0-12.png",
            //   grade: 1,
            // },
            // {
            //   id: 13,
            //   image: "G0-13.png",
            //   grade: 1,
            // },
            // {
            //   id: 14,
            //   image: "G0-14.png",
            //   grade: 1,
            // },
          ],
          rest: [
            {
              id: 1,
              name: 'With Rest',
              cn: '包含休止符',
              grade: ['pg', 'g1', 'g2', 'g3'],
              checked: false,
            },
            {
              id: 2,
              name: 'Without Rest',
              cn: '不包含休止符',
              grade: ['pg', 'g1', 'g2', 'g3'],
              checked: false,
            },
          ],
          keySignature: {
            major: [
              { id: 1, name: "C+", grade: ['pg', 'g1', 'g2', 'g3'], checked: false },
              { id: 2, name: "G+", grade: ['g1', 'g2', 'g3'], checked: false },
              { id: 3, name: "D+", grade: ['g2', 'g3'], checked: false },
              { id: 4, name: "A+", grade: [], checked: false },
              { id: 5, name: "E+", grade: [], checked: false },
              { id: 6, name: "B+", grade: [], checked: false },
              { id: 7, name: "F+", grade: ['g1', 'g2', 'g3'], checked: false },
              { id: 8, name: "Bb+", grade: ['g3'], checked: false },
              { id: 9, name: "Eb+", grade: ['g3'], checked: false },
              { id: 10, name: "Ab+", grade: [], checked: false },
              { id: 11, name: "Db+", grade: [], checked: false },
              { id: 12, name: "Gb+", grade: [], checked: false },
              { id: 13, name: "F#+", grade: [], checked: false },
              { id: 14, name: "C#+", grade: [], checked: false },
            ],
            minor: [
              { id: 15, name: "C-", grade: [], checked: false },
              { id: 16, name: "G-", grade: ['g2', 'g3'], checked: false },
              { id: 17, name: "D-", grade: ['g1', 'g2', 'g3'], checked: false },
              { id: 18, name: "A-", grade: ['g1', 'g2', 'g3'], checked: false },
              { id: 19, name: "E-", grade: ['g2', 'g3'], checked: false },
              { id: 20, name: "B-", grade: ['g3'], checked: false },
              { id: 21, name: "F-", grade: [], checked: false },
              { id: 22, name: "Bb-", grade: [], checked: false },
              { id: 23, name: "Eb-", grade: [], checked: false },
              { id: 24, name: "Ab-", grade: [], checked: false },
              { id: 25, name: "Db-", grade: [], checked: false },
              { id: 26, name: "Gb-", grade: [], checked: false },
              { id: 27, name: "F#-", grade: [], checked: false },
              { id: 28, name: "C#-", grade: [], checked: false },
            ]
          },
          handSelection: [
            { id: 1, name: 'Right hand only', cn: '仅右手', grade: ['pg', 'g1'], checked: false },
            { id: 2, name: 'Left hand only', cn: '仅左手', grade: ['pg', 'g1'], checked: false },
            { id: 3, name: 'Alternate hands', cn: '左右手轮奏', grade: ['pg', 'g1', 'g2', 'g3'], checked: false },
            { id: 4, name: 'Both hands', cn: '左右手合奏', grade: ['g2', 'g3'], checked: false },
          ]
        },
        rendering: {
          handPosition: {
            width: '21%',
            name: 'Hand Position',
            cn: '音域范围'
          },
          fingering: {
            width: '20%',
            name: 'Fingering',
            cn: '特殊指法'
          },
          interval: {
            width: '13%',
            name: 'Interval',
            cn: '音程范围'
          },
          timeSignature: {
            width: '8%',
            name: 'Time',
            cn: '拍号'
          },
          rhythm: {
            width: '18%',
            name: 'Rhythm',
            cn: '节奏型'
          },
          rest: {
            width: '12%',
            name: 'Rest',
            cn: '休止符'
          },
          keySignature: {
            width: '14%',
            name: '+ Key -',
            cn: '调号'
          },
          handSelection: {
            width: '14%',
            name: 'Hand Selection',
            cn: '单/双手'
          },
        },
        other: {
          upbeat: {
            name: 'Upbeat',
            cn: '不完全开头',
            checked: false,
          },
          tie: {
            name: 'Tie',
            cn: '同音连线',
            checked: false,
          },
          dynamics: {
            name: 'Dynamics',
            cn: '表情记号',
            checked: false,
          },
          articulation: {
            name: 'Articulation',
            cn: '奏法记号',
            checked: false,
          },
          accidental: {
            name: 'Accidental',
            cn: '临时升降号',
            checked: false,
          },
        },
      }
    },
    methods: {
      setCource(course) {
        this.course = course;
        if (course === 'freestyle') {
          this.selectedGrade = null;
        }
      },
      setGrade(grade, index) {
        if (this.course === 'freestyle') return;

        // grade 4 and above are disabled
        if (index > 3) return;

        this.grade.forEach((item) => {
          item.selected = false;
        })
        grade.selected = !grade.selected;

        if (grade.selected) {
          this.selectedGrade = grade.id;
        } else {
          this.selectedGrade = null;
        }
      },
      tooltipOpen(index, fromKey) {
        let count = index;
        for (const key of Object.keys(this.taggings)) {
          if (this.taggings.hasOwnProperty(key) && key !== 'keySignature') {
            if (fromKey !== key) {
              count += this.taggings[key].filter(x => x.tipImage).length;
            } else {
              break;
            }
          }
        }

        console.log('count', count);
        console.log('tooltipOpen', this.$refs.tooltip);

        const ele = this.$refs.tooltip[count].$el;

        const box = ele.getBoundingClientRect();
        console.log('box', box);

        const tooltip = ele.children[0];
        const tooltipBox = tooltip.getBoundingClientRect();

        // document.getElementById('test').style.left = (box.right - wrapper.width / 10) + 'px';
        // document.getElementById('test').style.width = box.width + 'px';
        // document.getElementById('test').style.height = box.height + 'px';

        const wrapper = document.querySelector('.sg-taggings-area');
        console.log('wrapper', wrapper);
        const wrapperBox = wrapper.getBoundingClientRect();

        console.log('wrapper', wrapperBox);
        console.log('window.innerWidth', window.innerWidth);
        tooltip.style.left = (box.right - ((window.innerWidth - wrapperBox.width) / 2) + 30) + 'px';
        tooltip.style.top = box.top - wrapperBox.top + 12 + 'px';
      },
      select(item, taggings) {
        // 非keySignature
        if (Array.isArray(taggings)) {
          taggings.forEach((item) => {
            item.checked = false;
          });
        }

        // keySignature
        if (taggings.major) {
          taggings.major.forEach((item) => {
            item.checked = false;
          });
          taggings.minor.forEach((item) => {
            item.checked = false;
          });
        }

        item.checked = true;
      },
      getSelected(tagging) {
        // 非keySignature
        if (Array.isArray(tagging)) {
          const selected = tagging.find((item) => item.checked);
          return selected;
        }

        // keySignature
        if (tagging['major']) {
          const selected = tagging['major'].find((item) => item.checked);
          if (selected) {
            return selected;
          }
        }
        if (tagging['minor']) {
          const selected = tagging['minor'].find((item) => item.checked);
          if (selected) {
            return selected;
          }
        }
        return null;
      },
      removeSelection(tagging) {
        // 非keySignature
        if (Array.isArray(tagging)) {
          tagging.forEach((item) => {
            item.checked = false;
          });
        } else {
          // keySignature
          if (tagging['major']) {
            tagging['major'].forEach((item) => item.checked = false);
          }
          if (tagging['minor']) {
            tagging['minor'].forEach((item) => item.checked = false);
          }
        }
      },
      getRhythmById(id) {
        return this.taggings.rhythm.find((item) => item.id === id);
      },
      // 设置查询乐谱的每个参数的取值范围，这个参数根据选中的grade不同而不同
      getRange(key, params) {
        if (key.includes('.')) {
          // keySignature分为major和minor，参数传进来的格式是keySignature.major或者keySignature.minor
          const keys = key.split('.');
          if (this.course === 'freestyle') {
            params[keys[0] + 'Range'] = this.taggings[keys[0]][keys[1]].map(x => x.id);
          } else {
            params[keys[0] + 'Range'] = this.taggings[keys[0]][keys[1]].filter(x => x.grade.includes(this.selectedGrade)).map(x => x.id);
          }
        } else {
          if (this.course === 'freestyle') {
            params[key + 'Range'] = this.taggings[key].map(x => x.id);
          } else {
            params[key + 'Range'] = this.taggings[key].filter(x => x.grade.includes(this.selectedGrade)).map(x => x.id);
          }
        }
      },
      getReqParams() {
        let params = {
          count: this.scoreCount,
          idNotIn: this.idNotIn
        };

        for (const key of Object.keys(this.taggings)) {
          if (this.taggings.hasOwnProperty(key)) {
            if (key !== 'keySignature') {
              const item = this.taggings[key].find(x => x.checked);
              if (item) {
                params[key] = item.id;
              }
              this.getRange(key, params);
            } else {
              params.keySignatureRange = [...this.taggings.keySignature.major.map(x => x.id), ...this.taggings.keySignature.minor.map(x => x.id)];
              let item = this.taggings.keySignature.major.find(x => x.checked);
              if (item) {
                params[key] = item.id;
                this.getRange('keySignature.major', params);
              } else {
                let item = this.taggings.keySignature.minor.find(x => x.checked);
                if (item) {
                  params[key] = item.id;
                  this.getRange('keySignature.minor', params);
                }
              }
            }
          }
        }

        for (const key of Object.keys(this.other)) {
          if (this.other.hasOwnProperty(key)) {
            if (this.other[key].checked) {
              params[key] = true;
            }
          }
        }
        return params;
      },
      async generateScores() {
        if (this.loading) return;

        this.$EventBus.$emit('stopPlay');
        this.loading = true;

        const params = this.getReqParams();
        console.log('params', params);

        const res = await this.$store
          .dispatch("GET_SCORE_GENERATOR_SCORES", params)
          .catch((err) => {
            console.error(err);
            alert(error);
          });

        this.originalScores = res.data.scores;
        const scores = await this.mapScores(res.data.scores);
        this.matchedScoreCount = res.data.scoreCount;

        this.afterGetScores(res, scores);
      },
      async generateScoresForSelected() {
        const checkedScores = this.getCheckedScores();
        if (!checkedScores.length) {
          alert(this.language === 'cn' ? '请选择要更新的曲谱' : 'Please select at least one score');
          return;
        };

        if (this.loading) return;

        this.$EventBus.$emit('stopPlay');
        this.loading = true;

        const params = this.getReqParams();
        params.count = checkedScores.length;

        const res = await this.$store
          .dispatch("GET_SCORE_GENERATOR_SCORES", params)
          .catch((err) => {
            console.error(err);
            alert(error);
          });

        const scores = await this.mapScores(res.data.scores);
        let index = 0;

        this.scores.forEach((sc) => {
          if (sc.checked) {
            sc.score = scores[index].score;
            sc.id = scores[index].id;
            sc.scoreSvg = scores[index].scoreSvg;
            sc.taggings = scores[index].taggings;

            this.originalScores[index] = res.data.scores[index];
            index++;
          }
        });

        this.$set(this, 'scores', this.scores);

        this.afterGetScores(res, this.scores);
      },
      async generateScoreAt(index) {
        this.$EventBus.$emit('stopPlay');

        if (!this.scores[index]) {
          alert('Please check the score index.');
          return;
        }

        this.scores[index].loading = true;
        const params = this.getReqParams();
        params.count = 1;

        const res = await this.$store
          .dispatch("GET_SCORE_GENERATOR_SCORES", params)
          .catch((err) => {
            console.error(err);
            alert(error);
          });

        if (res.data && res.data.scores.length) {
          const scores = await this.mapScores([res.data.scores[0]]);
          console.log(scores);

          this.scores[index].score = scores[0].score;
          this.scores[index].taggings = scores[0].taggings;
          this.scores[index].id = scores[0].id;
          this.scores[index].scoreSvg = scores[0].scoreSvg;
          this.setCurrentPDFForSvgScore(0);
          this.scores[index].loading = false;

          this.originalScores[index] = res.data.scores[0];

          this.afterGetScores(res, this.scores);
        } else {
          alert('No scores found.');
        }
      },
      afterGetScores(res, scores) {
        // 查询到的乐谱要轮询，就是说相同查询条件下已展示过的乐谱，再次点击Generate时就不显示了，直至所有乐谱都展示过一次
        // 所有查询到的乐谱已经随机展示过一遍了
        if (res.data.isCompleted) {
          this.idNotIn = res.data.scores.map(x => x.id);
        } else {
          // idNotIn里保存已经展示过的乐谱id，API查询时会避开这些乐谱
          this.idNotIn = [...this.idNotIn, ...res.data.scores.map(x => x.id).filter(x => !this.idNotIn.some(y => y === x))];
        }
        console.log(scores);
        this.$set(this, 'scores', scores);

        if (scores.length) {
          // this.setCurrentPDF(0);
          this.setCurrentPDFForSvgScore(0);
          this.initPrevNextBtns();
        }

        this.loading = false;

        setTimeout(() => {
          this.setSvgStyle();
        });
      },
      async loadSvg(svgUrls) {
        if (!svgUrls) return null;

        const res = await axios.all(svgUrls.map(x => axios.get(x.startsWith('http') ? x : this.scores_base_url + x)));

        let svg = '';
        for (const r of res) {
          svg += r.data.replace(/<svg width=".*?" height=".*?"/, '<svg');
        }

        return svg;
      },
      setSvgStyle() {
        const setSvgHeight = (container) => {
          const svgs = container.querySelectorAll('svg');
          const last = svgs[svgs.length - 1];
          if (last) {
            const allStaffLines = last.querySelectorAll('.StaffLines');
            const lastLinePosition = allStaffLines[allStaffLines.length - 1].getBoundingClientRect();
            const containerPosition = container.getBoundingClientRect();

            // 乐谱svg是一个类似A4纸的页面，如果乐谱很短（或分页后最后一页），下面会有大片空白
            // 这里找到最后一根五线谱的线的位置，减去顶部参数选择部分的高度，重新设置contianer的高度，以去除空白
            const margin = this.settings.isShowTitle ? 100 : 0;
            container.style.height = `${lastLinePosition.bottom - containerPosition.top + margin}px`;
          }
        }

        const hideScoreItems = (container) => {
          let selector = [];
          if (!this.settings.isShowLyrics) {
            selector.push('.Lyrics');
          }
          if (!this.settings.isShowStaffText) {
            selector.push('.StaffText');
          }
          if (!this.settings.isShowTitle) {
            selector.push('.Text');
          }

          const svgs = container.querySelectorAll('svg');
          for (const svg of svgs) {
            const lyrics = svg.querySelectorAll(selector.join(', '));
            lyrics.forEach(x => {
              x.classList.add('hidden');
            });
          }

          // show score
          setTimeout(() => {
            container.style.opacity = 1;
          });
        }

        const svgContainers = document.querySelectorAll('.sg-score-svg');
        for (const container of svgContainers) {
          setSvgHeight(container);
          hideScoreItems(container);
        }
      },
      async changeLanguage(lang) {
        this.language = lang;
        // 重新配置score才可以让score下面的taggings改变文字
        if (this.originalScores) {
          const scores = await this.mapScores(this.originalScores);
          this.$set(this, 'scores', scores);
        }
      },
      getTaggingName(item, key) {
        const tagging = this.taggings[key].find(x => x.id === item[key]);
        if (tagging) {
          return tagging[this.language] || tagging.name || '--';
        }
        return '--';
      },
      async mapScores(scores) {
        let mappedScores = [];
        for (const item of scores) {
          const svg = await this.loadSvg(item.resources?.filter(x => x.resourceType === 2)?.sort((a, b) => { return a.pageId > b.pageId ? 1 : -1 }).map(x => x.filePath));

          mappedScores.push({
            id: item.id,
            score: item.filePath,
            scoreSvg: svg,
            taggings: {
              handPosition: this.getTaggingName(item, 'handPosition'),
              interval: this.getTaggingName(item, 'interval'),
              timeSignature: this.taggings.timeSignature.find(x => x.id === item.timeSignature)?.name || '--',
              rhythm: this.taggings.rhythm.find(x => x.id === item.rhythm)?.id || null,
              rest: this.getTaggingName(item, 'rest'),
              keySignature: this.taggings.keySignature.major.find(x => x.id === item.keySignature)?.name || this.taggings.keySignature.minor.find(x => x.id === item.keySignature)?.name || '--',
              handSelection: this.getTaggingName(item, 'handSelection'),
            },
            loading: false,
            checked: true,
          })
        }

        return mappedScores;
      },
      initPrevNextBtns() {
        this.hasPrev = false;
        if (this.getCheckedScores().length > 1) {
          this.hasNext = true;
        } else {
          this.hasNext = false;
        }
      },
      setCurrentPDF(index) {
        this.currentPDFIndex = index;
        const checkedScores = this.getCheckedScores();
        if (checkedScores.length) {
          this.currentPDF = this.scores_base_url + this.getCheckedScores()[this.currentPDFIndex].score;
        }
      },
      setCurrentPDFForSvgScore(index) {
        this.currentPDFIndex = index;
        const checkedScores = this.getCheckedScores();
        if (checkedScores.length) {
          this.currentPDF = this.getCheckedScores()[this.currentPDFIndex].scoreSvg;
        }
      },
      prevPDF() {
        const checkedScores = this.getCheckedScores();
        if (this.currentPDFIndex > 0) {
          this.currentPDFIndex -= 1;
          this.currentPDF = null;
          this.hasNext = true;
          if (this.currentPDFIndex === 0) {
            this.hasPrev = false;
          }

          setTimeout(() => {
            // this.currentPDF = this.scores_base_url + checkedScores[this.currentPDFIndex].score;
            this.currentPDF = checkedScores[this.currentPDFIndex].scoreSvg;

            setTimeout(() => {
              this.setSvgStyle();
            });
          }, 10);
        }
      },
      nextPDF() {
        const checkedScores = this.getCheckedScores();
        if (this.currentPDFIndex < checkedScores.length - 1) {
          this.currentPDFIndex += 1;
          this.currentPDF = null;
          this.hasPrev = true;
          if (this.currentPDFIndex === checkedScores.length - 1) {
            this.hasNext = false;
          }

          setTimeout(() => {
            // this.currentPDF = this.scores_base_url + checkedScores[this.currentPDFIndex].score;
            this.currentPDF = checkedScores[this.currentPDFIndex].scoreSvg;

            setTimeout(() => {
              this.setSvgStyle();
            });
          }, 10);
        }
      },
      saveAsPDF() {
        this.$EventBus.$emit('stopPlay');

        if (this.getCheckedScores().length) {
          this.setCurrentPDFForSvgScore(0);
          this.initPrevNextBtns();
          this.showPDF = true;
          setTimeout(() => {
            this.setSvgStyle();
          });
        } else {
          alert(this.language === 'cn' ? '请选择曲谱' : 'Please select at least one score');
        }
      },
      generatePDF() {
        this.loadingPDF = true;

        // setTimeout because it would stuck showing the loading animation
        setTimeout(() => {
          this.$refs.html2Pdf.generatePdf();
        }, 10);
      },
      progress(progress) {
        if (progress === 100) {
          this.loadingPDF = false;
        }
      },
      getPDFScore() {
        return this.scores_base_url + this.scores[this.currentPDFIndex].score;
      },
      getCheckedScores() {
        return this.scores.filter(x => x.checked);
      },
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      windowResize() {
        this.setSvgStyle();
      }
    },
    mounted() {
      for (let i = 0; i < 8; i++) {
        this.grade.push({
          id: `g${i + 1}`,
          name: `Grade ${i + 1}`,
          selected: false,
          locked: i > 2
        })
      }
    },
    created() {
      window.addEventListener("resize", this.windowResize);
    },
    destroyed() {
      window.removeEventListener("resize", this.windowResize);
    },
  }
</script>

<style lang="scss">
  #app {
    .b-slider.is-info .b-slider-fill {
      background-color: #9ebdc6 !important;
    }

    .b-checkbox.checkbox input[type=checkbox]:checked+.check.is-info {
      background: #efb1a8 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E") no-repeat center center;
      border-color: #efb1a8;
    }
  }

  .b-tooltip {
    position: initial !important;
  }

  .b-tooltip .tooltip-content {
    background-color: white !important;
    width: 120px !important;
    padding: 12px;
  }

  .b-tooltip.is-right .tooltip-content::before {
    border-top: 8px solid transparent !important;
    border-right: 8px solid lightgray !important;
    border-bottom: 8px solid transparent !important;
  }

  .sg-disabled {
    .sg-block {
      cursor: default;
      background-color: lightgray;
    }
  }

  .sg-block {
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    color: white;
    text-align: center;
    background-color: #9ebdc6;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 0 !important;
  }

  .sg-block-on {
    background-color: #efb1a8;
  }

  i.fa-lock {
    font-size: 12px;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .cursor-default {
    cursor: default !important;
  }

  .container-box {
    border-radius: 6px;
    min-width: 100px;
  }

  .box-header {
    background-color: #9ebdc6;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: white;
    height: 30px;
    line-height: 30px;
    padding: 0 12px;
  }

  .box-content {
    padding: 12px;
    border: 1px solid lightgray;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow-y: auto;
    /* display: flex; */
    flex-direction: column;
  }

  .vue-html2pdf {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  object {
    svg {
      width: auto;
      height: auto;
    }
  }


  .sg-loader {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  .sg-loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left: 4px solid #efb1a8;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }

  .sg-single-score .sg-loader,
  .generate-pdf .sg-loader {
    border-top: 4px solid #9ebdc6;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width: 1080px) {
    .sg-block {
      height: 32px;
      line-height: 32px;
    }

    .sg-taggings-area {
      width: 98%;
    }

    .sg-selected-taggings {
      font-size: 14px;
    }

    .box-content {
      font-size: 14px;
      padding: 12px 4px;
    }

    .sg-rhythm {
      img {
        height: 26px;
      }
    }
  }

  @media screen and (min-width: 1281px) and (max-width: 1550px) {
    .sg-block {
      height: 36px;
      line-height: 36px;
    }

    .sg-taggings-area {
      width: 80%;
    }

    .box-content {
      font-size: 14px;
      padding: 12px 4px;
    }

    .sg-rhythm {
      img {
        height: 26px;
      }
    }
  }

  @media screen and (min-width: 1550px) and (max-width: 1850px) {
    .sg-taggings-area {
      width: 80%;
    }
  }
</style>