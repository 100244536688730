<template>
  <div id="app">
    <Loading v-if="$store.state.isLoading" />
    <NotificationPop v-if="$store.state.showNotification" />
    <router-view />
  </div>
</template>
<script>
  export default {
    name: "App",
    components: {},
    data() {
      return {
        navCollapsed: false,
        exceptions: ["Login", "Register", "ForgotPassword", "ResetPassword"],
      };
    },
    methods: {},
    mounted() {
      // ScoreGenerator is for external use
      // check if user is logged in and he is not on any of the exceptions
      if (!this.$loggedAdmin() && !this.exceptions.includes(this.$route.name) && this.$route.name !== 'ScoreGenerator') {
        this.$adminlogout();
      }

      // check navCollapsed state
      if (this.$store.state.navCollapsed) {
        this.navCollapsed = true;
      } else {
        this.navCollapsed = false;
      }
    },
  };
</script>
<style lang="scss">
  @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css");
  @import url("/fonts/Bravuraf.otf");

  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  // import smufl font
  .with-symbols {
    font-family: "Bravura" !important;
    line-height: 1 !important;
    font-weight: normal;
    font-style: normal;

    option {
      line-height: 1 !important;
    }
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .scoreContent {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
    background: #f5f5f5;

    .score-body {
      width: 100%;

      @media (max-width: 800px) {
        width: 100%;

        .right-widget {
          margin-left: 1rem;
        }
      }
    }
  }

  .analytics-content {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    height: 70vh;
    overflow-y: auto;
    width: 95%;

    .analytic-dashes {
      border-bottom: 1px solid #eaeaea;
      width: 95%;
      padding-bottom: 2rem;

      .analytic-title {
        font-size: 19px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 20px;
        margin-top: 2rem;
      }

      .dash-items {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;

        .analytic-dash {
          margin-bottom: 20px;
          background: #fff;
          border-radius: 5px;
          padding: 10px;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            margin-bottom: 10px;
            padding-bottom: 10px;
            display: block;
            border-bottom: 1px solid #eaeaea;
          }

          .stats {
            display: flex;
            flex-direction: column;

            .stats-item {
              width: 100%;
              font-size: 14px;
              font-weight: 500;
              color: #000000;
              margin-bottom: 10px;
              display: flex;
              justify-content: space-between;

              span {
                font-weight: 600;
                color: #000000;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  .d-flex-btn {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
</style>