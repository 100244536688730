<template>
  <div class="ScoreLibraryUpload">
    <ConfirmPop v-if="showConfirm" @cancel="showConfirm = false" @confirm="removeScore"
      :text="'Are you sure you want to delete the selected score?'" />
    <ConfirmPop v-if="showDeleteAllConfirm" @cancel="showDeleteAllConfirm = false" @confirm="deleteSelectedScores"
      :text="'Are you sure you want to delete all selected scores?'" :confirmMsg="'Yes, delete them'" />
    <div class="scoreContent">
      <ScoreLibrarySidebar />
      <div class="score-body">
        <ScoreLibraryTopbar :pageTitle="'EE Score Library'" :back="true" />
        <div class="right-widget">
          <div class="libraries-list">
            <router-link :to="{ name: 'ScoreLibraryUpload', params: { id: library.id } }" class="list-item"
              :class="checkLibrary(library.id) ? 'active' : ''" v-for="library in libraries" :key="library.id">{{
              library.name }}</router-link>
            <router-link :to="{ name: 'ManageLibraries' }" class="list-item">
              <i class="fa fa-plus"></i>
            </router-link>
          </div>
          <div class="form-section">
            <form action>
              <div class="form-group">
                <label for="Select Library">Select Library</label>
                <div class="label-group grid-3">
                  <select name="title" id="title" v-model="searchQuery.curriculum" @change="curriculumChanged()">
                    <option value="KungFuSightReading">
                      KungFu Sight Reading
                    </option>
                    <option value="PianoAdventure">Piano Adventure</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="Select Score Placement">Select Score Placement</label>
                <div class="label-group grid-3">
                  <select name="title" id="title" v-model="searchQuery.gradeId" @change="getLevels()">
                    <option value="">Select Grade</option>
                    <option v-for="grade in grades" :value="grade.gradeId" :key="grade.gradeId">
                      {{ grade.gradeName }}
                    </option>
                  </select>
                  <select name="title" id="title" v-model="searchQuery.levelId" @change="getStages()">
                    <option value="">Select Level</option>
                    <option v-for="level in levels" :value="level.levelId" :key="level.levelId">
                      {{ level.levelName }}
                    </option>
                  </select>
                  <select name="title" id="title" v-model="searchQuery.stageId" @change="getScores()">
                    <option value="">Select Stage</option>
                    <option v-for="stage in stages" :value="stage.stageId" :key="stage.stageId">
                      {{ stage.stageName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <ScoreParameters :searchQuery="searchQuery" @parameterChanged="getScores()"></ScoreParameters>
              </div>

              <div class="form-group">
                <label for="Select Library">Upload Scores [MusicXML format]</label>
                <div class="label-group grid-1">
                  <!-- dropzone -->
                  <section class="dropzone">
                    <div v-if="dropFiles.length == 0">
                      <b-field>
                        <b-upload v-model="dropFiles" multiple drag-drop expanded accept=".musicxml">
                          <section class="section">
                            <div class="content">
                              <h3>Drag & Drop or</h3>
                              <p>browse</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span v-for="(file, index) in dropFiles" :key="index" class="tag is-EEskyblue">
                          {{ file.name }}
                          <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
                        </span>
                      </div>
                    </div>
                    <!-- html/css progress bar with text -->
                    <div v-else>
                      <div class="progress">
                        <div class="progress-bar" :class="uploadProgress == 100 ? 'complete' : ''" role="progressbar"
                          :style="{ width: uploadProgress + '%' }" aria-valuenow="25" aria-valuemin="0"
                          aria-valuemax="100">
                          Uploading scores {{ uploadProgress.toFixed(0) }}%
                        </div>
                      </div>
                    </div>
                  </section>
                  <p v-if="showUploadMsg">
                    {{ uploadedScores.length }} out of
                    {{ dropFiles.length }} scores uploaded. Please review and
                    set tagging below.
                  </p>
                </div>
              </div>
            </form>

            <div class="uploaded-section" v-if="uploadDone">
              <ScoreLibraryUploadedScores :uploadedScores="uploadedScores" @removeUploadedScore="
                  (data) => {
                    removeUploadedScore(data);
                  }
                " @publishScores="
                  (data) => {
                    publishScores(data);
                  }
                " />
            </div>

            <!-- preview listing -->
            <div v-show="startUpload == false">
              <div class="scores-section" v-if="allScores.length > 0 || searchText !== ''">
                <div class="score-top">
                  <div class="score-top-left">
                    <!-- levels icon -->
                    <div class="levels-icon">
                      <i class="fa fa-list"></i>
                    </div>
                    <div class="search-input">
                      <input type="text" placeholder="Search score..." @input="searchScores()" v-model="searchText" />
                    </div>
                  </div>
                  <div class="score-top-right">
                    <div class="edit edit-item" v-if="!startEdit" @click="startEdit = true">
                      <i class="fa fa-edit"></i>
                      <label for>Edit</label>
                    </div>
                    <div class="edits" v-else>
                      <div class="deselect-all edit-item" @click="deselectAllScores()"
                        v-if="selectedScores.length == allScores.length">
                        <i class="fa fa-square edit-item"></i>
                        <label for>Deselect All</label>
                      </div>
                      <div class="select-all edit-item" @click="selectAllScores()" v-else>
                        <i class="fa fa-check-square"></i>
                        <label for>Select All</label>
                      </div>
                      <div class="delete-btn edit-item" @click="showDeleteAllConfirm = true">
                        <i class="fa fa-trash"></i>
                        <label for>Delete</label>
                      </div>
                      <div class="export edit-item" @click="exportSelectedScores()">
                        <i class="fa fa-download"></i>
                        <label for>Export</label>
                      </div>
                      <div class="cancel edit-item" @click="cancelEdit()">
                        <i class="fa fa-times"></i>
                        <label for>Cancel</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="score-section-body" v-for="(score, index) in allScores" :key="score.id">
                  <div class="d-flex-btn">
                    <div class="score-identity">
                      <label for class="score-name">{{ score.name }}</label>
                      <!-- on and off toggler -->
                      <div @click.prevent="switchScore(score)">
                        <b-switch v-model="score.scoreEnabled"></b-switch>
                      </div>
                    </div>
                    <div class="accompaniment" v-if="score.hasAccompaniment">
                      <audio controls :src="$store.state.scores_base_url + score.accompanimentPath">
                        <source :src="
                            $store.state.scores_base_url +
                            score.accompanimentPath
                          " type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                      <!-- <div class="flex gap-2 justify-center update-accompaniment" v-if="score.checked">
                        <b-button type="is-ghost" @click="changeAccompaniment(score)">Change</b-button>
                        <FileUploader ref="accompanimentUploader" class="w-0" :filePath="'/img/library/'"
                          @fileUploaded="accompanimentChanged($event, score)" :uploadText="'Change'" />
                        <b-button type="is-ghost">Remove</b-button>
                      </div> -->
                    </div>
                  </div>
                  <div class="score-xml" :id="`score-xml${score.id}`">
                    <div class="circular-checkbox">
                      <!-- input checkbox -->
                      <input type="checkbox" class="square-checkbox" @change="selectScore(score)"
                        v-model="score.checked" v-if="startEdit" />
                    </div>
                    <!-- <img :src="`/img/scores/${score.image}`" alt /> -->
                    <PreviewScore :filename="scores_base_url + score.filePath"></PreviewScore>
                    <div :ref="`sheetMusicContainer${score.id}`" :id="`sheetMusicContainer${score.id}`"
                      :class="`sheetMusicContainer`"></div>
                  </div>
                  <div class="edit-score" v-show="score.checked">
                    <div class="score-placement">
                      <div class="input-group">
                        <label for="">Score name:</label>
                        <input type="text" v-model="score.name" />
                      </div>
                      <p>Placement</p>
                      <div class="input-group place">
                        <select v-model="score.gradeId" @change="getLevels(score)">
                          <option v-for="grade in gradesForEdit" :value="grade.gradeId" :key="grade.gradeId">
                            {{ grade.gradeName }}
                          </option>
                        </select>
                        <select v-model="score.levelId" @change="getStages(score)">
                          <option :value="score.levelId" v-if="
                              levelsForEdit.length == 0 && score.levelId != null
                            ">
                            {{ getLevel(score.levelId).levelName }}
                          </option>
                          <option v-for="level in levelsForEdit" :value="level.levelId" :key="level.levelId">
                            {{ level.levelName }}
                          </option>
                        </select>
                        <select v-model="score.stageId">
                          <option :value="score.stageId" v-if="
                              stagesForEdit.length == 0 && score.stageId != null
                            ">
                            {{ getStage(score.stageId).stageName }}
                          </option>
                          <option v-for="stage in stagesForEdit" :value="stage.stageId" :key="stage.stageId">
                            {{ stage.stageName }}
                          </option>
                        </select>

                        <select v-model="score.scoreType">
                          <!-- <option value="">Select Score Type</option> -->
                          <option v-for="type in scoreTypes" :value="type.id" :key="type.id">
                            {{ type.name }}
                          </option>
                        </select>
                      </div>

                      <ScoreParameters :searchQuery="score"></ScoreParameters>

                    </div>
                    <div class="save-remove">
                      <div class="save-buttons">
                        <button class="save" @click="saveChanges(score)">
                          Save
                        </button>
                        <button class="remove" @click="
                            scoreToDelete = score;
                            showConfirm = true;
                          ">
                          Remove Score
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="empty-section" v-else>
                <div class="empty-section-body">
                  <div class="empty-icon">
                    <i class="fa fa-folder-open"></i>
                  </div>
                  <div class="empty-text">
                    <h3>This Library is empty</h3>
                    <p>No scores found in this placement</p>
                  </div>
                </div>
              </div>
              <!-- <div :ref="`sheetMusicContainer${allScores[0].id}`" :class="`sheetMusicContainer`"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExportScores :scores="selectedScores" :exportData="exportData" v-if="startExportScores"
      @close="startExportScores = false" />
    <AdminRegFooter />
  </div>
</template>

<script>
  // @ is an alias to /src
  import ScoreLibraryUploadedScores from "./components/ScoreLibraryUploadedScores.vue";
  import scoreLibraryMix from '@/mixin/scoreLibraryMix.vue';

  export default {
    name: "ScoreLibraryUpload",
    mixins: [scoreLibraryMix],
    components: { ScoreLibraryUploadedScores },
    data() {
      return {
        selectedOptions: [],

        grades: [],
        levels: [],
        stages: [],
        levelsForEdit: [],
        stagesForEdit: [],
        gradesForEdit: [],
        allScores: [],
        scoresToSearch: [],
        uploadedScores: [],
        selectedScores: [],
        startEdit: false,
        exportData: {
          grade: "",
          level: "",
          stage: "",
          module: "",
          curriculum: "",
        },
        searchQuery: {
          gradeId: "",
          levelId: "",
          stageId: "",
          moduleId: "",
          curriculum: "KungFuSightReading",
          handPosition: null,
          interval: null,
          rhythm: null,
          rest: null,
          keySignature: null,
          timeSignature: null,
          tie: null,
          handSelection: null,
          fingering: [],
          others: [],
        },
        showConfirm: false,
        showDeleteAllConfirm: false,
        scoreToDelete: null,
        libraries: [],
        tune: "/xml/libraryG1-L1-S2_A1-5-33.musicxml",
        dropFiles: [],
        uploadProgress: 0,
        startUpload: false,
        showUploadMsg: false,
        failedFiles: 0,
        filesToUpload: 0,
        uploadDone: false,
        scores_base_url: this.$store.state.scores_base_url,
        moduleId: null,
        searchText: "",

        startExportScores: false,
        exportData: {
          gradeId: "",
          gradeName: "",
          levelId: "",
          levelName: "",
          stageId: "",
          stageName: "",
          moduleId: "",
          moduleName: "",
          curriculum: "KungFuSightReading",
        },
        showError: false,
      };
    },
    methods: {
      cancelEdit() {
        this.startEdit = false;
        // make all score not checked
        this.allScores.forEach((score) => {
          score.checked = false;
        });
      },

      getRhythms(grade) {
        return this.rhythms;
        // return this.rhythms.filter((rhythm) => rhythm.grade == grade);
      },
      getRests(grade) {
        // return this.rests.filter((rest) => rest.grade == grade);
        return this.rests;
      },

      getLibraries() {
        this.$startLoader();
        this.$store
          .dispatch("GET_LIBRARIES")
          .then((res) => {
            this.$stopLoader();
            this.libraries = res.data.modules;
          })
          .catch((err) => {
            this.$stopLoader();
            this.$showNotification(err.error);
          });
      },
      deleteDropFile(index) {
        this.dropFiles.splice(index, 1);
      },
      selectScore(score) {
        // score.checked = !score.checked;
        if (score.checked) {
          this.selectedScores.push(score);
          this.sele;
        } else {
          this.selectedScores = this.selectedScores.filter(
            (selectedScore) => selectedScore.id !== score.id
          );
        }
      },
      selectAllScores() {
        this.selectedScores = [];
        this.allScores.forEach((score) => {
          score.checked = true;
          this.selectedScores.push(score);
        });
      },
      deselectAllScores() {
        this.selectedScores = [];
        this.allScores.forEach((score) => {
          score.checked = false;
        });
      },
      switchScore(score) {
        this.$startLoader();
        score.scoreEnabled = !score.scoreEnabled;
        this.$store
          .dispatch("TOGGLE_SCORE", {
            scoreEnabled: score.scoreEnabled,
            id: score.id,
          })
          .then((res) => {
            this.$stopLoader();
            // this.$showNotification(res.message);
          })
          .catch((err) => {
            this.$stopLoader();
            // this.$showNotification(err.error);
          });
      },
      getScoreTypeNumber(scoreType) {
        const type = this.scoreTypes.find(scoreType => scoreType.name == scoreType);
        if (type) return type.id;
        return 0;
        // switch (scoreType) {
        //   case "Normal":
        //     return 0;
        //   case "Warmap":
        //     return 1;
        //   case "Exercise":
        //     return 2;
        //   default:
        //     return 0;
        // }
      },
      setNullValues(score) {
        // check each element on serachQuery object that is equal to string "null" and replace it with null
        for (let key in score) {
          if (score[key] == "null") {
            score[key] = null;
          }
        }
      },
      saveChanges(score) {
        this.$startLoader();
        // score.scoreType = this.getScoreTypeNumber(score.scoreType);
        this.setNullValues(score);
        this.$store
          .dispatch("UPDATE_SCORE", score)
          .then((res) => {
            this.$stopLoader();
            this.$showNotification(res.data.message);
            // reload page after 2 seconds
            setTimeout(() => {
              // window.location.reload();
            }, 2000);
          })
          .catch((err) => {
            console.error(err);
            this.$stopLoader();
            this.$showNotification(err.error || err.message);
          });
      },
      removeScore() {
        this.showConfirm = false;
        this.$startLoader();
        this.$store
          .dispatch("DELETE_SCORE", this.scoreToDelete.id)
          .then((res) => {
            // remove score from allScores array
            this.allScores = this.allScores.filter(
              (score) => score.id !== this.scoreToDelete.id
            );
            // remove score from scoresToSearch array
            this.scoresToSearch = this.scoresToSearch.filter(
              (score) => score.id !== this.scoreToDelete.id
            );
            this.$stopLoader();
            this.$showNotification(res.data.message);
          })
          .catch((err) => {
            this.$stopLoader();
            this.$showNotification(err.error);
          });
      },
      searchScores() {
        console.log("searching scores");
        this.allScores = this.scoresToSearch.filter((score) =>
          score.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
      },
      deleteSelectedScores() {
        this.allScores = this.allScores.filter(
          (score) => !this.selectedScores.includes(score)
        );
        this.showDeleteAllConfirm = false;
        this.$startLoader();
        // convert selected scores array to array of ids
        let selectedScoreIdsArray = this.selectedScores.map(
          (selectedScore) => selectedScore.id
        );
        if (selectedScoreIdsArray.length == 0) {
          this.$stopLoader();
          this.$showNotification("Please select at least one score");
          return;
        } else {
          this.$store
            .dispatch("DELETE_SELECTED_SCORES", {
              selected: selectedScoreIdsArray,
            })
            .then((res) => {
              this.$stopLoader();
              this.$showNotification(res.data.message);
            })
            .catch((err) => {
              this.$stopLoader();
              this.$showNotification(err.error);
            });
        }
      },
      exportSelectedScores() {
        this.$startLoader();
        // convert selected scores array to array of ids
        let selectedScoreIdsArray = this.selectedScores.map(
          (selectedScore) => selectedScore.id
        );
        if (selectedScoreIdsArray.length == 0) {
          this.$stopLoader();
          this.$showNotification("Please select at least one score");
          return;
        } else {
          this.exportData.grade = this.getGrade(this.selectedScores[0].gradeId);
          this.exportData.level = this.getLevel(this.selectedScores[0].levelId);
          this.exportData.stage = this.getStage(this.selectedScores[0].stageId);
          this.exportData.module = this.libraries.find(
            (library) => library.id == this.selectedScores[0].moduleId
          );
          this.$stopLoader();
          this.startExportScores = true;
          return;
          this.$store
            .dispatch("EXPORT_SCORES", { selected: selectedScoreIdsArray })
            .then((res) => {
              this.$stopLoader();
              this.$showNotification(res.message);
            })
            .catch((err) => {
              this.$stopLoader();
              this.$showNotification(err.error);
            });
        }
      },
      checkLibrary(libraryId) {
        // if library is equal to router parameter id, then return true
        if (libraryId == this.$route.params.id) {
          return true;
        } else {
          return false;
        }
      },
      removeUploadedScore(score) {
        console.log(score);
        this.uploadedScores = this.uploadedScores.filter(
          (uploadedScore) => uploadedScore.fileName !== score.fileName
        );
        // if uploadedScores array is empty, then refresh page after 2 seconds
        if (this.uploadedScores.length == 0) {
          // show notification
          this.$showNotification("All scores removed");
          this.startUpload = false;
          this.showUploadMsg = false;
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      },
      getGrades() {
        this.$startLoader();
        this.$store
          .dispatch("GET_GRADES")
          .then((res) => {
            this.$stopLoader();
            this.grades = res.data.grades;
            this.gradesForEdit = res.data.grades;
            this.searchQuery.levelId = "";
            this.searchQuery.stageId = "";
          })
          .catch((err) => {
            this.$stopLoader();
          });
      },
      getLevels(edit = null) {
        this.$startLoader();
        let id = this.searchQuery.gradeId;
        if (edit != null) {
          id = edit.gradeId;
          this.levelsForEdit = [];
        } else {
          this.levels = [];
          this.stages = [];
          this.searchQuery.levelId = "";
          this.searchQuery.stageId = "";
          this.allScores = [];
          this.selectedScores = [];
        }
        this.$store
          .dispatch("GET_LEVELS", id)
          .then((res) => {
            this.$stopLoader();
            if (edit) {
              this.levelsForEdit = res.data.levels;
              // edit.levelId = "";
              // edit.stageId = "";
              this.stagesForEdit = [];
            } else {
              this.levels = res.data.levels;
              this.stages = [];
              this.searchQuery.stageId = "";
            }
          })
          .catch((err) => {
            this.$stopLoader();
          });
      },
      getStages(edit = null) {
        this.$startLoader();
        let id = this.searchQuery.levelId;
        if (edit) {
          id = edit.levelId;
          this.stagesForEdit = [];
        } else {
          this.allScores = [];
          this.selectedScores = [];
          this.stages = [];
          this.searchQuery.stageId = "";
        }
        this.$store
          .dispatch("GET_STAGES", id)
          .then((res) => {
            this.$stopLoader();
            if (edit) {
              this.stagesForEdit = res.data.stages;
            } else {
              this.stages = res.data.stages;
            }
          })
          .catch((err) => {
            this.$stopLoader();
          });
      },
      getScores() {
        if (this.searchQuery.gradeId == "") {
          this.$showNotification("Please select a grade");
          return;
        }
        if (this.searchQuery.levelId == "") {
          this.$showNotification("Please select a level");
          return;
        }
        if (this.searchQuery.stageId == "") {
          this.$showNotification("Please select a stage");
          return;
        }
        if (this.searchQuery.curriculum == "") {
          this.$showNotification("Please select a library type");
          return;
        }
        // check each element on serachQuery object that is equal to string "null" and replace it with null
        for (let key in this.searchQuery) {
          if (this.searchQuery[key] == "null") {
            this.searchQuery[key] = null;
          }
        }
        this.$startLoader();
        this.$store
          .dispatch("GET_SCORES", this.searchQuery)
          .then((res) => {
            this.$stopLoader();
            this.getFingering(res.data.scores);
            this.getOthers(res.data.scores);
            this.allScores = res.data.scores;
            this.scoresToSearch = res.data.scores;
            this.levelsForEdit = this.levels;
            this.stagesForEdit = this.stages;
          })
          .catch((err) => {
            this.$stopLoader();
            if (err.error) {
              this.$showNotification(err.error);
            } else {
              this.$showNotification("Something went wrong!!!");
              // window.location.reload();
            }
          });
      },
      async uploadScores() {
        if (this.dropFiles.length > 0 && this.dropFiles.length <= 10) {
          this.startUpload = true;
          this.uploadedScores = [];
          this.failedFiles = 0;
          this.filesToUpload = this.dropFiles.length;
          this.uploadProgress = 0;
          let uploadIncreaseRate = 100 / this.filesToUpload;
          // upload files
          for (const score of this.dropFiles) {
            try {
              // get name of the file without extension to be uploaded
              let fileName = score.name.split(".")[0];
              const res = await this.$store.dispatch("UPLOAD_SCORE_FILE", {
                file: score,
              });

              let uploadedFile = {};
              uploadedFile.fileName = res.data.fileName;
              uploadedFile.filePath = res.data.filePath;
              uploadedFile.file = this.scores_base_url + uploadedFile.filePath;
              uploadedFile.scoreType = '';
              // uploadedFile.name = res.data.fileName without extension
              uploadedFile.name = fileName;
              uploadedFile.handPosition = null;
              uploadedFile.handSelection = null;
              uploadedFile.interval = null;
              uploadedFile.rhythm = null;
              uploadedFile.rest = null;
              uploadedFile.keySignature = null;
              uploadedFile.timeSignature = null;
              uploadedFile.tie = null;
              uploadedFile.moduleId = this.moduleId;
              uploadedFile.gradeId = this.searchQuery.gradeId;
              uploadedFile.levelId = this.searchQuery.levelId;
              uploadedFile.stageId = this.searchQuery.stageId;
              uploadedFile.fingering = [];
              uploadedFile.others = [];

              console.log('uploadedFile', uploadedFile)
              this.uploadedScores.push(uploadedFile);
            } catch (err) {
              this.failedFiles++;
            }

            // increase uploadProgress until all files are uploaded
            this.uploadProgress += uploadIncreaseRate;
          }
        }
        if (this.dropFiles.length > 10) {
          this.$showNotification("You can upload maximum 10 files at a time");
          this.dropFiles = [];
          this.startUpload = false;
        }

        // upload progress by adding 1% every 50ms and add a complete class to the progress bar after 5s
        // let uploadProgress = 0;
        // let uploadProgressInterval = setInterval(() => {
        //   uploadProgress++;
        //   this.uploadProgress = uploadProgress;
        //   if (uploadProgress === 100) {
        //     clearInterval(uploadProgressInterval);
        //     this.showUploadMsg = true;
        //   }
        // }, 50);

        // setTimeout(() => {
        //   this.showUploadMsg = true;
        // }, 5000);
      },
      publishScores(scores) {
        if (scores.length > 0) {
          this.$startLoader();
          // loop through scores and make range, interval, rhythm, rest, keySignature, timeSignature and tie string if they are integers
          scores.forEach((score) => {
            // change moduleId to int
            score.moduleId = parseInt(score.moduleId, 10);
            score.curriculum = this.searchQuery.curriculum;
          });

          this.$store
            .dispatch("ADD_SCORE", {
              scores: scores,
            })
            .then((res) => {
              this.$stopLoader();
              scores = [];
              this.dropFiles = [];
              this.uploadedScores = [];
              this.uploadDone = false;
              this.startUpload = false;
              this.showUploadMsg = false;
              this.uploadProgress = 0;
              this.$showNotification(res.data.message);
              // reload the page after 3s
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            })
            .catch((err) => {
              this.$stopLoader();
              if (err.error) {
                this.$showNotification(err.error);
              } else {
                this.$showNotification("Something went wrong!!!");
              }
            });
        } else {
          this.$showNotification("Please upload a score file");
        }
      },
      curriculumChanged() {
        this.getScores();
      },
      changeAccompaniment(score) {
        const allChecked = this.allScores.filter(x => x.checked);
        const index = allChecked.indexOf(score);
        console.log('allChecked', allChecked);
        console.log('index', index);
        // this.allScores.filter(x => x.id !== score.id).forEach(x => x.checked = false);
        this.$refs.accompanimentUploader[index].showUploader();
      },
      accompanimentChanged($event, score) {
        score.accompanimentPath = $event;
      }
    },
    mounted() {
      this.searchQuery.moduleId = this.$route.params.id;
      this.moduleId = this.$route.params.id;
      this.getLibraries();
      this.getGrades();
    },
    watch: {
      dropFiles: function () {
        this.uploadScores();
      },
      "$route.params.id": function () {
        this.searchQuery.moduleId = this.$route.params.id;
        this.moduleId = this.$route.params.id;
        this.dropFiles = [];
        this.uploadedScores = [];
        this.uploadDone = false;
        this.startUpload = false;
        this.showUploadMsg = false;
        this.uploadProgress = 0;
        this.allScores = [];
        this.searchQuery.gradeId = "";
        this.searchQuery.levelId = "";
        this.searchQuery.stageId = "";
      },
      uploadProgress: function () {
        if (this.uploadProgress > 100) {
          this.uploadProgress = 100;
        }
        if (this.uploadProgress == 100) {
          this.showUploadMsg = true;
          if (this.failedFiles > 0) {
            this.$showNotification(`${this.failedFiles} files failed to upload`);
          }
          this.uploadDone = true;
        }
        if (
          this.dropFiles.length ==
          this.uploadedScores.length + this.failedFiles
        ) {
          this.showUploadMsg = true;
          this.uploadProgress = 100;
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  $colors: (
    "is-EEskyblue": #3dd1d1,
  );
  $EEskyblue: #3dd1d1;
  $EEorange: #ffc87d;

  .ScoreLibraryUpload {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;



    .score-body {
      .right-widget {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: auto;
        // overflow-x: hidden;
        height: calc(88vh - 100px);
        padding-bottom: 100px;
        margin-right: 8px;

        .libraries-list {
          display: flex;
          width: 100%;
          min-height: 55px;
          overflow-x: auto;

          .list-item {
            width: 150px;
            padding: 10px 0;
            min-width: 150px;
            background-color: #eee;
            border: none;
            border-radius: 5px;
            margin: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: #777;
            cursor: pointer;
            text-decoration: none;
            font-weight: 600;
            transition: all 0.7s ease-in-out;

            &:hover {
              background-color: orange;
              color: #fff;
            }

            &.active {
              background-color: orange;
              color: #fff;
            }
          }
        }

        .form-section {
          width: 88%;

          form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 2rem;
            margin-top: 4rem;
            width: 100%;

            .form-group {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              width: 100%;

              label {
                font-size: 1.25rem;
                font-weight: 600;
                color: $EEskyblue;
                margin-bottom: 10px;
              }

              .label-group {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                column-gap: .5rem;
                row-gap: 1rem;

                &.grid-3 {
                  width: 100%;
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                }

                &.grid-m {
                  width: 100%;
                  display: grid;
                  grid-template-columns: repeat(10, 1fr);
                }

                .dropd {
                  display: inline-block;
                }

                select {
                  width: 100%;
                  padding: 10px 0;
                  background-color: #fff;
                  /* box-shadow: 1px 2px 3px 4px #8d8d8d1a; */
                  border: 1px solid #ccc;
                  border-radius: 5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 14px;
                  color: #777;
                  cursor: pointer;
                  text-decoration: none;
                  font-weight: 600;
                  transition: all 0.7s ease-in-out;

                  // style select dropdown options (height, hover color)
                  option {
                    height: 50px;

                    &:hover {
                      background-color: $EEskyblue;
                      color: #fff;
                    }
                  }
                }
              }

              .dropzone {
                width: 100%;
                height: auto;
                border: none;
                border-radius: 5px;
                display: flex;
                flex-direction: column;

                .section,
                .upload,
                .upload-draggable {
                  height: 100%;
                  background: #fff;
                }

                .content {

                  h3,
                  p {
                    display: inline-block;
                  }

                  h3 {
                    font-size: 1.25rem;
                    font-weight: 400;
                    margin-right: 5px;
                    // margin-bottom: 10px;
                  }
                }

                .progress {
                  width: 100%;
                  height: 27px;
                  background-color: #eee;
                  border-radius: 50px;
                  position: relative;

                  .progress-bar {
                    width: 0%;
                    height: 100%;
                    background-color: $EEorange;
                    border-radius: 50px;
                    color: white;
                    font-size: 1.1rem;
                    transition: all 500ms ease-in-out;

                    // when progress bar is 100% wide, show the checkmark
                    &.complete {
                      background-color: $EEskyblue;

                      &:after {
                        content: "✔";
                        position: absolute;
                        right: 10px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .scores-section {
          margin-top: 4rem;
          background: #fff;
          padding: 2rem;
          width: calc(100% - 4.4rem);
          border-radius: 20px;

          .score-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .score-top-left {
              display: flex;
              align-items: center;

              .levels-icon {
                font-size: 1.5rem;
                color: #777;
                margin-right: 1rem;
              }

              .search-input {
                width: 100%;

                input {
                  width: 300px;
                  padding: 10px 10px;
                  background-color: #fff;
                  /* box-shadow: 1px 2px 3px 4px #8d8d8d1a; */
                  border: 1px solid #ccc;
                  border-radius: 5px;
                  margin: 5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 16px;
                  color: #777;
                  cursor: pointer;
                  text-decoration: none;
                  font-weight: 400;
                  transition: all 0.7s ease-in-out;
                }
              }
            }

            .score-top-right {
              display: flex;
              align-items: center;

              .edits {
                display: flex;
              }

              .edit-item {
                display: flex;
                align-items: center;
                margin-right: 1.25rem;
                cursor: pointer;

                i {
                  font-size: 1.25rem;
                  color: #777;
                  margin-right: 0.25rem;
                }

                label {
                  font-size: 1.25rem;
                  font-weight: 300;
                  color: #777;
                  cursor: pointer;
                }
              }
            }
          }

          .score-section-body {
            width: 100%;
            margin-top: 2rem;
            border-bottom: 2px dashed #dfdfdf;
            padding-bottom: 2rem;

            .score-identity {
              display: flex;
              align-items: center;
              width: 100%;

              .score-name {
                font-size: 1.25rem;
                font-weight: 500;
                color: #555;
                margin-right: 15px;
              }

              .on-off-tog {
                display: flex;
                align-items: center;

                .on-off-tog-btn {
                  width: 50px;
                  height: 25px;
                  background-color: #ccc;
                  border-radius: 25px;
                  position: relative;
                  cursor: pointer;

                  .on-off-tog-btn-circle {
                    width: 20px;
                    height: 20px;
                    background-color: #fff;
                    border-radius: 50%;
                    position: absolute;
                    top: 2.5px;
                    left: 2.5px;
                    transition: all 0.5s ease-in-out;
                  }
                }

                .on-off-tog-btn.on {
                  background-color: $EEskyblue;

                  .on-off-tog-btn-circle {
                    left: 27.5px;
                  }
                }
              }
            }

            .score-xml {
              width: 100%;
              margin-top: 1rem;
              display: grid;
              grid-template-columns: 10% 90%;

              .circular-checkbox {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                .b-checkbox.checkbox input[type="checkbox"]+.check {
                  border-radius: 50%;
                }
              }

              // img {
              //   width: 100%;
              //   height: 100%;
              //   object-fit: contain;
              // }
            }

            .score-placement {
              width: 100%;
              display: flex;
              flex-direction: column;
              // justify-content: space-around;
              padding-top: 1rem;
              row-gap: 1rem;

              p {
                font-size: 1.25rem;
                font-weight: 500;
                color: #555;
                margin: 15px 0;
              }

              .input-group {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: .5rem;

                &.place {
                  select {
                    width: 200px;
                    height: auto;
                    padding: 10px 10px;
                    background-color: #fff;
                    /* box-shadow: 1px 2px 3px 4px #8d8d8d1a; */
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    font-size: 16px;
                    color: #555;
                  }
                }

                &.placement-3 {
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  grid-column-gap: 2rem;
                }

                input {
                  width: 200px;
                  padding: 10px 10px;
                  background-color: #fff;
                  /* box-shadow: 1px 2px 3px 4px #8d8d8d1a; */
                  border: 1px solid #ccc;
                  border-radius: 5px;
                  font-size: 16px;
                  color: #555;
                }

                select,
                .dropd {
                  width: 120px;
                  height: 30px;
                  border: 1px #d9d9d9 solid;
                  border-radius: 5px;
                  padding: 0 10px;
                  font-size: 1rem;
                  color: #555;
                  background: #fff;
                  cursor: pointer;

                  &.error {
                    border: 1px #ff00009b solid;
                  }
                }
              }
            }

            .save-remove {
              width: 100%;
              display: flex;
              justify-content: space-around;
              margin-top: 2.5rem;

              .save-buttons {
                display: flex;
                column-gap: 1rem;

                button {
                  width: 150px;
                  padding: 10px;
                  font-size: 18px;
                  font-weight: 700;
                  color: #555;
                  border: none;
                  border-radius: 10px;
                  display: inline-block;
                  cursor: pointer;

                  &.save {
                    background-color: $EEskyblue;
                  }

                  &.remove {
                    background-color: transparent;
                    border-bottom: 1px #333 solid;
                    border-radius: 0;
                    padding-bottom: 5px;
                  }
                }
              }
            }
          }
        }

        .empty-section {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          margin-top: 2rem;

          .empty-section-body {
            display: flex;
            flex-direction: column;
            align-items: center;

            .empty-icon {
              font-size: 3rem;
              color: #777;
              margin-bottom: 1rem;
            }

            .empty-text {
              font-size: 1rem;
              color: #777;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }

  .square-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    // change the color of the checkmark and the background
    &:checked &.check {
      background-color: $EEskyblue;
      border-color: $EEskyblue;
      border-radius: 0;

      .checkmark {
        color: #fff;
      }
    }
  }

  .dropd {
    width: 120px;
    height: 43px;
    border: 1px solid #cccccc00;
    margin-top: 4px;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 1rem;
    color: #555;
    background: #fff;
    box-shadow: 1px 2px 3px 4px #8d8d8d1a;
    cursor: pointer;

    &.error {
      border: 1px #ff00009b solid;
    }
  }
</style>