<template>
  <div class="score-parameters">
    <div class="flex gap-2">
      <!-- select for hand position -->
      <select name="handPosition" id="handPosition" :class="[
          !searchQuery.handPosition && showError ? 'error' : '',
        ]" @change="parameterChanged()" v-model="searchQuery.handPosition">
        <option value="null">Hand Position</option>
        <option v-for="range in handPositions" :key="range.id" :value="range.id">
          {{ range.name }}
        </option>
      </select>

      <!-- select for intervals -->
      <select name="interval" id="interval" :class="[
          !searchQuery.interval && showError ? 'error' : '',
        ]" @change="parameterChanged()" v-model="searchQuery.interval">
        <option value="null">Interval</option>
        <option v-for="interval in intervals" :key="interval.id" :value="interval.id">
          {{ interval.name }}
        </option>
      </select>

      <!-- select for rhythms -->
      <EEDropdown :items="rhythms" class="dropd" :class="[
          !searchQuery.rhythm && showError ? 'error' : '',
        ]" :listName="'Rhythm'" :value="searchQuery.rhythm" :long="true" @selected="
          (data) => {
            searchQuery.rhythm = data;
            parameterChanged();
          }
        " />
      <!-- select for rests -->
      <select name="rest" id="rest" :class="[
          !searchQuery.rest && showError ? 'error' : '',
        ]" @change="parameterChanged()" v-model="searchQuery.rest">
        <option value="null">Rest</option>
        <option v-for="rest in rests" :key="rest.id" :value="rest.id">
          {{ rest.name }}
        </option>
      </select>


      <!-- select for keySignature -->
      <select name="keyMajor" id="keyMajor" :class="[
          !searchQuery.keySignature && showError
            ? 'error'
            : '',
        ]" @change="parameterChanged()" v-model="searchQuery.keySignature">
        <option value="null">Major</option>
        <option v-for="keySignature in keySignatures.major" :key="keySignature.id" :value="keySignature.id">
          {{ keySignature.name }}
        </option>
      </select>
      <select name="keyMinor" id="keyMinor" :class="[
          !searchQuery.keySignature && showError
            ? 'error'
            : '',
        ]" @change="parameterChanged()" v-model="searchQuery.keySignature">
        <option value="null">Minor</option>
        <option v-for="keySignature in keySignatures.minor" :key="keySignature.id" :value="keySignature.id">
          {{ keySignature.name }}
        </option>
      </select>
    </div>

    <div class="flex gap-2 mt-4">
      <!-- select for timeSignature -->
      <select name="timeSignature" id="timeSignature" :class="[
          !searchQuery.timeSignature && showError
            ? 'error'
            : '',
        ]" @change="parameterChanged()" v-model="searchQuery.timeSignature">
        <option value="null">Time Signature</option>
        <option v-for="timeSignature in timeSignatures" :key="timeSignature.id" :value="timeSignature.id">
          {{ timeSignature.name }}
        </option>
      </select>

      <!-- select for handSelections -->
      <select name="handSelection" id="handSelection" :class="[
          !searchQuery.handSelection && showError
            ? 'error'
            : '',
        ]" @change="parameterChanged()" v-model="searchQuery.handSelection">
        <option value="null">Hand Selection</option>
        <option v-for="hand in handSelections" :key="hand.id" :value="hand.id">
          {{ hand.name }}
        </option>
      </select>

      <!-- select for fingering -->
      <b-tooltip :label="getFingeringDisplayText(searchQuery.fingering)" type="is-light">
        <b-dropdown v-model="searchQuery.fingering" @change="fingeringChanged" multiple aria-role="list">
          <template #trigger>
            <b-button type="is-primary" icon-right="menu-down">
              Fingering ({{ searchQuery.fingering.length }})
            </b-button>
          </template>

          <b-dropdown-item v-for="hand in fingering" :value="hand.id" aria-role="listitem">
            <span>{{hand.name}}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-tooltip>

      <!-- select for others -->
      <b-tooltip :label="getOthersDisplayText(searchQuery.others)" type="is-light">
        <b-dropdown v-model="searchQuery.others" @change="othersChanged" multiple aria-role="list">
          <template #trigger>
            <b-button type="is-primary" icon-right="menu-down">
              Others ({{ searchQuery.others.length }})
            </b-button>
          </template>

          <b-dropdown-item v-for="hand in others" :value="hand.id" aria-role="listitem">
            <span>{{hand.name}}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
  import scoreLibraryMix from '@/mixin/scoreLibraryMix.vue';

  export default {
    name: "ScoreParameters",
    mixins: [scoreLibraryMix],
    props: {
      showError: {
        type: Boolean,
        default: false,
      },
      searchQuery: {
        gradeId: "",
        levelId: "",
        stageId: "",
        moduleId: "",
        curriculum: "KungFuSightReading",
        handPosition: null,
        interval: null,
        rhythm: null,
        rest: null,
        keySignature: null,
        timeSignature: null,
        tie: null,
        handSelection: null,
        fingering: [],
        others: [],
      },
    },
    data() {
      return {
        user: {},
        activeLink: null,
        navCollapse: false,
        permissions: [],
      };
    },
    methods: {
      parameterChanged() {
        console.log(this.searchQuery);
        this.$emit('parameterChanged', this.searchQuery);
      },

      // looks like Buefy's dropdown change event triggers before v-model changes, so setTimeout
      fingeringChanged() {
        setTimeout(() => {
          console.log(this.searchQuery.fingering);
          this.fingering.forEach(o => {
            this.searchQuery[o.key] = null;
          })
          this.searchQuery.fingering.forEach(f => {
            const key = this.fingering.find(x => x.id == f).key;
            this.searchQuery[key] = true;
          });
          console.log(this.searchQuery);

          this.parameterChanged();
        }, 100);
      },
      othersChanged() {
        setTimeout(() => {
          console.log(this.searchQuery.others);
          this.others.forEach(o => {
            this.searchQuery[this.getName(o.name)] = null;
          })
          this.searchQuery.others.forEach(other => {
            const name = this.others.find(x => x.id == other).name;
            this.searchQuery[this.getName(name)] = true;
          });

          this.parameterChanged();
        }, 100);
      },
      getName(text) {
        return text.substr(0, 1).toLowerCase() + text.substr(1).replace(' ', '');
      }

    },
  }
</script>

<style lang="scss">
  .score-parameters {
    width: 100%;

    .flex {
      &>* {
        flex: 1;
      }
    }

    .dropdown {
      width: 100%;

      .button.is-primary.is-focused,
      .button.is-primary:focus {
        color: #777;
        background-color: white;
      }


      .dropdown-trigger {
        width: 100%;
      }

      button {
        width: 100%;
        background-color: white;
        color: #777;
        border: 1px solid #ccc;
        /* box-shadow: 1px 2px 3px 4px rgba(141, 141, 141, 0.1019607843); */
      }

      .dropdown-menu {
        width: 100%;
      }
    }

    select {
      border: 1px solid #ccc;
      border-radius: 4px;
      color: #777;
      padding: 8px 4px;
    }

    select.error,
    .EEDropdown.error {
      border: 1px solid rgba(255, 0, 0, .6078431373);
    }
  }
</style>